import axios from "axios";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { ClientInfosContext } from "../../context/ClientInfosContext";

const Contact = ({ id }) => {
  const [contact, setContact] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [role, setRole] = useState();
  const { clientId /* setShowClientNav */ } = useContext(ClientInfosContext);
  console.log("clientId :>> ", clientId);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/user/entity/${id}`)
      .then((res) => {
        console.log("res.data :>> ", res.data);
        setContact(res.data.user);
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
  }, [id]);

  const deleteConsultantFromEntity = (id) => {
    console.log("entity idd :>> ", id);
    console.log("id:id :>> ", id);
    try {
      axios
        .delete(`${process.env.REACT_APP_API_URL}/api/user/delete/${id}`)
        .then((res) => {
          toast.success("success", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
          console.log("res.data :>> ", res.data);
          setTimeout(() => {
            const getConsultant = () => {
              axios
                .get(
                  `${process.env.REACT_APP_API_URL}/api/user/entity/${clientId}`,
                  {
                    headers: {
                      accessToken: localStorage.getItem("accessToken"),
                    },
                  }
                )
                .then((res) => {
                  console.log("j'arrive a venir ici");
                  console.log(
                    "res from getConsultant from file upload :>> ",
                    res
                  );

                  setContact(res.data.user);
                })
                .catch((err) => {
                  console.log("err :>> ", err);
                });
            };
            getConsultant();
          }, 300);
        })
        .catch((err) => {
          console.log("err :>> ", err);
          toast.error("an error was occured", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
        });
    } catch (error) {
      console.log("error :>> ", error);
      toast.error("an error was occured", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const handleAddContact = async (e) => {
    e.preventDefault();
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/user/register`, {
        email,
        client_role: role,
        password,
        entityId: clientId,
        role: "client",
      })
      .then((res) => {
        toast.success("success", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setTimeout(() => {
          const getConsultant = () => {
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/api/user/entity/${clientId}`,
                {
                  headers: {
                    accessToken: localStorage.getItem("accessToken"),
                  },
                }
              )
              .then((res) => {
                console.log("j'arrive a venir ici");
                console.log(
                  "res from getConsultant from file upload :>> ",
                  res
                );

                setContact(res.data.user);
              })
              .catch((err) => {
                console.log("err :>> ", err);
              });
          };
          getConsultant();
        }, 300);
      })
      .catch((err) => {
        console.log("err :>> ", err);
        toast.error("an error was occured", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      });
  };

  return (
    <section className="section-contact">
      <h2 className="title-team-members">Client contact</h2>
      <div className="table-title">
        <div className="title-name">Contact</div>
        <div className="title-roles">Role</div>
      </div>
      {contact &&
        contact.map((el, index) => {
          console.log(el);
          return (
            <div key={index} className="name-role-settings">
              <p className="name-settings">{el.email}</p>
              <p className="role-settings">{el.client_role}</p>
              {el.client_role === "manager" ? (
                ""
              ) : (
                <RiDeleteBinLine
                  className="delete-consultant-btn"
                  size={20}
                  onClick={() => {
                    deleteConsultantFromEntity(el.id);
                  }}
                />
              )}
            </div>
          );
        })}

      <div>
        <h2 className="title-add-contact">Add contact</h2>
        <form onSubmit={handleAddContact} className="container-add-contact">
          <div>
            <div className="input-email">
              <label>Email</label>
              <input
                className="input-first-step"
                name="contact"
                type="text"
                placeholder="Contact@gmail.com"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input-email">
              <label className="label-link">Rôle</label>
              <input
                className="input-first-step"
                name="role"
                type="text"
                placeholder="Manager"
                onChange={(e) => setRole(e.target.value)}
              />
            </div>
          </div>
          <div className="div-password-button">
            <div className="input-email">
              <label className="label-link">Password</label>
              <input
                className="input-first-step"
                name="role"
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              style={{ cursor: "pointer" }}
              className="add-member"
              type="submit"
            >
              Add Contact
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Contact;
