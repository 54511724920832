import React from "react";
import logo from "../../assets/logo-lune.svg";
import close from "../../assets/close.svg";
import { BsArrow90DegLeft, BsArrow90DegRight } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { multiStepContext } from "../../context/stepContext";

const NavStepper = (props) => {
  const history = useHistory();

  const { setStep, currentStep } = useContext(multiStepContext);

  const checkNumber = (number) => {
    if (number === 0) {
      return 1;
    } else {
      return number;
    }
  };
  return (
    <nav className="nav-stepper">
      <div className="img-logo">
        <img src={logo} alt="logo" className="logo" />
        <div className="text-div">
          <h1 className="heading04--Regular">Add a new client</h1>
          <p>/ Validate the steps to add your client</p>
        </div>
      </div>
      <div className="step-div-btn-container">
        <div className="step-div">
          <ul className="step-div-liste">
            <li className={currentStep === 1 ? "li-nav-color" : ""}>
              Basic information
            </li>
            <div>/</div>
            <li className={currentStep === 2 ? "li-nav-color" : ""}>Team</li>
            {/*  <li className={currentStep === 3 ? "li-nav-color" : ""}>Files</li> */}
            <div>/</div>
            <li className={currentStep === 3 ? "li-nav-color" : ""}>Tools</li>
            {/*  <li className={currentStep === 4 ? "li-nav-color" : ""}>Tools</li> */}
            <div>/</div>
            <li className={currentStep === 4 ? "li-nav-color" : ""}>
              Last step
            </li>
          </ul>
        </div>
      </div>
      <div className="btn-container">
        {currentStep === 1 ? (
          ""
        ) : (
          <BsArrow90DegLeft
            size={20}
            style={{ cursor: "pointer" }}
            onClick={() =>
              setStep((currentStep) => {
                let newCurrentStep = currentStep - 1;
                return checkNumber(newCurrentStep);
              })
            }
          />
        )}
        {currentStep === 4 /* || currentStep === 1 */ ? (
          ""
        ) : (
          <BsArrow90DegRight
            size={20}
            style={{ cursor: "pointer" }}
            onClick={() => setStep((currentStep) => currentStep + 1)}
          />
        )}
      </div>
      <div className="close-btn">
        <img
          src={close}
          alt="close button"
          onClick={() => history.push("/accueil/home")}
        />
      </div>
    </nav>
  );
};

export default NavStepper;
