import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NotionRenderer } from "react-notion";

const PlanningPost = () => {
  const [blockMap, setBlockMap] = useState({});
  const { planningTitle } = useParams();

  console.log("splanTitle :>> ", planningTitle);
  async function PlanningNameToId(planningSlug) {
    const planningDatabase = await fetch(
      "https://notion-api.splitbee.io/v1/table/c7d8d369a535406298b81d75ad8d0e5b"
    ).then((res) => res.json());
    console.log("NEWSDatabase :>> ", planningDatabase);

    try {
      const planning = planningDatabase
        .filter((planning) => planning.slug === planningSlug)
        .pop();
      console.log("seo.slug :>> ", planning.slug);
      return planning.id;
    } catch {
      return null;
    }
  }

  useEffect(() => {
    // Create an scoped async function in the hook
    async function getBlockId() {
      const PlanningId = await PlanningNameToId(planningTitle);

      const planningData = await fetch(
        "https://notion-api.splitbee.io/v1/page/" + PlanningId
      ).then((res) => res.json());

      setBlockMap(planningData);
      console.log("seodata :>> ", planningData);
      console.log("seoTitle :>> ", planningTitle);
      console.log("seoId :>> ", PlanningId);
      console.log("blockMap :>> ", blockMap);
    }
    getBlockId();
  }, [planningTitle]);

  return (
    <div>
      <NotionRenderer fullPage blockMap={blockMap} />
    </div>
  );
};

export default PlanningPost;
