import React, { useContext, useState } from "react";
// import NavAccueil from "./NavAccueil";
import { AuthContext } from "../auth/auth";
import { Redirect, Switch, Route, useRouteMatch } from "react-router-dom";
import NavSidebar from "./navSidebar/NavSidebar";
import AllClientsPage from "../pages/AllClientsPage";
// import Glide from "../pages/Glide/Glide";
//import Base from "../pages/KnowledgeBase";
import MySettings from "../pages/MySettings";
import ClientComponent from "./ClientComponent";

import ClientFile from "./clientPage/ClientFile";
import ClientDasboard from "./clientPage/ClientDasboard";
import ClientRoadmap from "./clientPage/ClientRoadmap";
import ClientTeam from "./clientPage/ClientTeam";
//import Formation from "../pages/Formation";
import ClientSetting from "./clientPage/ClientSetting";
import ManageConsultant from "../pages/ManageConsultant";

import NewsPost from "./notion/NEWS/NewsPost";

import PlanningPost from "./notion/Planning/PlanningPost";
import Support from "./Support";
import YNcompany from "./YNcompany";
import Formation from "../pages/Formation";
import KnowledgeBase from "../pages/KnowledgeBase";

const Accueil = () => {
  const { authState } = useContext(AuthContext);
  // const [arrow, setArrow] = useState(true)

  //const { arrow } = useContext(NavSidebarContext);
  /*   useEffect(() => {
    axios
      .get(url, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          setAuthState({ ...authState, status: false });
        } else {
          localStorage.setItem("user", JSON.stringify(res.data));
          setAuthState({
            username: res.data.email,
            role: res.data.role,
            entityId: res.data.entityId,
            status: true,
          });
        }
      });
  }, []);

  useEffect(() => {
    const getAllClient = () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/entity/all`, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          if (res.data.error) {
            console.log("res.data.error :>> ", res.data.error);
          }
          console.log("res.data :>> ", res.data.allEntity);
          setClients(res.data.allEntity);
          setLoading(false);
        })
        .catch((error) => {
          console.log("error :>> ", error);
          setLoading(true);
        });
    };
    getAllClient();

    return () => {
      setClients(null);
    };
  }, []);

  const userRole = () => {
    if (authState.role === "admin") {
      return (
        <div className="container">
          <div
            className="add-client-div"
            onClick={() => history.push("/create-client")}
          >
            <VscAdd size={55} />
            <span>Add new</span>
          </div>
          {loading ? (
            <Loading />
          ) : clients.length === 0 ? (
            <div>
              <h1>Pas encore de client ajouter</h1>
            </div>
          ) : (
            clients &&
            clients.map((client) => {
              const { id, name, image } = client;
              return (
                <div
                  key={id}
                  className="client-div"
                  onClick={() => history.push(`/client-page/${id}/client-file`)}
                >
                  <img src={image ? image : astro} alt={name} />
                  <h4>{client.name}</h4>
                </div>
              );
            })
          )}
        </div>
      );
    } else if (authState.role === "client") {
      return <ClientLoginAccueil />;
    }
  };
   className={arrow ? "sidebar-open" : "sidebar-closed"
 */
  let { path } = useRouteMatch();
  if (!authState.status) {
    return <Redirect to="/" />;
  }

  return (
    <section className="accueil">
      <NavSidebar />

      <section className="section-switch-nav">
        {/* <NavAccueil /> */}

        <div className="div-switch">
          <Switch>
            <Route exact path={`${path}/home`}>
              <AllClientsPage />
            </Route>
            <Route path={`${path}/support`}>
              <Support />
            </Route>
            {/* <Route path={`${path}/news`}>
            <News />
          </Route> */}

            <Route path={`${path}/mysettings`}>
              <MySettings />
            </Route>
            <Route path={`${path}/client-page/:id`}>
              <ClientComponent />
            </Route>
            <Route path={`${path}/client-file/:id`}>
              <ClientFile />
            </Route>
            <Route path={`${path}/client-page-dashboard/:id`}>
              <ClientDasboard />
            </Route>
            <Route path={`${path}/client-page-roadmap/:id`}>
              <ClientRoadmap />
            </Route>
            <Route path={`${path}/client-page-myTeam/:id`}>
              <ClientTeam />
            </Route>
            <Route path={`${path}/knowledgebase`}>
              {/* <Formation /> */}
              <KnowledgeBase />
            </Route>
            <Route path={`${path}/formation`}>
              {/* <Formation /> */}
              <Formation />
            </Route>
            <Route path={`${path}/client-setting`}>
              <ClientSetting />
            </Route>
            <Route path={`${path}/consultants`}>
              <ManageConsultant />
            </Route>
            <Route path={`${path}/YNcompany`}>
              <YNcompany />
            </Route>
            <Route path={`${path}/support`}>
              <Support />
            </Route>
            {/* //"ne pas oublier de changer ou ca push ref sur la newspost aussi" */}
            <Route path={`${path}/knowledges/:newsTitle`}>
              <NewsPost></NewsPost>
            </Route>
            <Route path={`${path}/onsite/:planningTitle`}>
              <PlanningPost></PlanningPost>
            </Route>
          </Switch>
        </div>
      </section>
    </section>
  );
};

export default Accueil;
