import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import loginvideo from "../assets/login.mp4";
import houstonlogo2 from "../assets/Houston-logo2.svg";
import { AuthContext } from "../auth/auth";
import { Redirect } from "react-router-dom";
import { Loader } from "@mantine/core";
import { useEffect } from "react";
import instance from "../axios/global";

const schema = yup.object().shape({
  email: yup.string().email().required("This field is required"),
  password: yup
    .string()
    .min(3, "Please fill in the password")
    .required("This field is required"),
});
const url = `${process.env.REACT_APP_API_URL}/api/user/login`;

const Connexion = ({ history }) => {
  const { setAuthState, authState } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await axios.post(url, data);
      localStorage.setItem("accessToken", res.data.token);
      setLoading(false);
      setAuthState({ ...authState, status: true });
      history.push("/accueil/home");
      setError(null);
    } catch (error) {
      console.error("error :>> ", error);
      setError(error.response.data.msg);
      setLoading(false);
      console.error("error :>> ", error.response.data.msg);
    }
  };
  if (authState.status) {
    return <Redirect to="/accueil/home" />;
  }

  return (
    <section className="accueil-section">
      <div className="leftdiv">
        <video
          autoPlay
          loop
          muted
          style={{
            position: "absolute",
            width: "60%",

            height: "100%",
            objectFit: "cover",
          }}
        >
          <source src={loginvideo} type="video/mp4" />
        </video>
      </div>
      <div className="log-in-div">
        <img src={houstonlogo2} alt="logo" />
        <div className="login-title">
          <h1>Log in</h1>
          <h4>Access your cockpit</h4>
        </div>
        {error && <span className="error-msg">{error}</span>}
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className="label-email-password" id="email">
            Email
          </label>
          <input
            className="input-email"
            {...register("email", {
              required: "Required",
            })}
            type="email"
            name="email"
            placeholder="Email"
          />
          <span className="warning-email">
            {errors.email && errors.email.message}
          </span>
          <label className="label-email-password" id="password">
            Password
          </label>
          <input
            className="input-password"
            {...register("password", {
              required: "Required",
            })}
            type="password"
            name="password"
            placeholder="Password"
          />
          <span className="warning-password">
            {errors.password && errors.password.message}
          </span>
          <div className="under-password">
            <div className="checkbox-remember">
              <input type="checkbox" id="rememberMe" name="rememberMe" />
              <label htmlFor="Remember">Remember me</label>
            </div>
            <span
              className="forget-password"
              onClick={() => history.push("/forget")}
            >
              Forget password?
            </span>
          </div>
          {loading ? (
            <Loader color="violet" size="lg" />
          ) : (
            <button className="button-connexion" type="submit">
              Login
            </button>
          )}
        </form>
        {/* <div className="down-login-div">
          <p>© 2021 - Yuri and Neil Group</p>
        </div> */}
      </div>
    </section>
  );
};

export default Connexion;
