import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { consultantRole } from "../data";
import Select from "react-select";
import { RiDeleteBinLine } from "react-icons/ri";
import confirmIcon from "../../assets/confirm-icon.svg";

const TeamMembers = ({ consultants, setConsultant, id }) => {
  const [consultantData, setConsultantData] = useState();
  const [consultantNameFromAPI, setConsultantNameFromApi] = useState();
  const deleteConsultantFromEntity = (consultantId) => {
    console.log("entity id :>> ", id);
    console.log("consultantId :>> ", consultantId);
    try {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/api/user/deletefromentity/${id}/${consultantId}`,
          {
            entityId: id,
            consultantId,
          }
        )
        .then((res) => {
          toast.success("success", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
          console.log("res.data :>> ", res.data);
          setTimeout(() => {
            const getConsultant = () => {
              axios
                .get(`${process.env.REACT_APP_API_URL}/api/entity/${id}`, {
                  headers: {
                    accessToken: localStorage.getItem("accessToken"),
                  },
                })
                .then((res) => {
                  console.log("j'arrive a venir ici");
                  console.log(
                    "res from getConsultant from file upload :>> ",
                    res
                  );

                  setConsultant(res.data.entity.user);
                })
                .catch((err) => {
                  console.log("err :>> ", err);
                });
            };
            getConsultant();
          }, 300);
        })
        .catch((err) => {
          console.log("err :>> ", err);
          toast.error("an error was occured", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
        });
    } catch (error) {
      console.log("error :>> ", error);
      toast.error("an error was occured", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const postConsultantInEntity = () => {
    const { member } = consultantData;
    try {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/user/addInEntity/${id}`, {
          member,
        })
        .then((res) => {
          console.log("res.data  pour poster entity:>> ", res.data);
          toast.success("success", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => {
            const getConsultant = () => {
              axios
                .get(`${process.env.REACT_APP_API_URL}/api/entity/${id}`, {
                  headers: {
                    accessToken: localStorage.getItem("accessToken"),
                  },
                })
                .then((res) => {
                  console.log("j'arrive a venir ici");
                  console.log(
                    "res from getConsultant from file upload :>> ",
                    res
                  );
                  setConsultant(res.data.entity.user);
                })
                .catch((err) => {
                  console.log("err :>> ", err);
                });
            };
            getConsultant();
          }, 300);
        })
        .catch((err) => {
          toast.error("an error was occured", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
          console.log("err :>> ", err);
        });
    } catch (error) {
      console.log("error :>> ", error);
      toast.error("an error was occured", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/user/allConsultants`)
      .then((res) => {
        setConsultantNameFromApi(res.data);
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted #F6F6F6",
      color: state.isSelected ? "#6935d7" : "black",

      padding: 20,
      borderRadius: "0.625rem",
      background: "white",
      "&:hover": {
        background: "#F6F6F6",
      },
    }),
    indicatorSeparator: () => ({
      border: "none",
    }),
    indicatorsContainer: () => ({
      borderLeft: "1px solid #f1f1f1",
      padding: "10px",
      marginRight: "2px",
      cursor: "pointer",
    }),

    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      width: 312,
      height: 57,
      backgroundColor: "white",
      border: "0.5px solid #ececee",
      borderRadius: "0.225rem",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const customStyles2 = {
    option: (provided, state) => ({
      ...provided,

      borderBottom: "1px dotted #F6F6F6",
      color: state.isSelected ? "#6935d7" : "black",
      padding: 20,
      background: "white",
      borderRadius: "0.625rem",
      "&:hover": {
        background: "#F6F6F6",
      },
    }),

    indicatorSeparator: () => ({
      border: "none",
    }),
    indicatorsContainer: () => ({
      borderLeft: "1px solid #f1f1f1",
      padding: "10px",
      marginRight: "2px",
      cursor: "pointer",
    }),

    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      width: 312,
      height: 57,
      backgroundColor: "white",
      border: "0.5px solid #ececee",
      borderRadius: "0.225rem",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const [memberRole] = useState(consultantRole);
  const [member, setMember] = useState([
    {
      name: "",
      role: "",
      id: "",
    },
  ]);

  const handle = (obj, index) => {
    console.log("obj :>> ", obj);
    const { name, value, id } = obj;
    const list = [...member];
    list[index][name] = value;
    // list[index]["id"] = id;
    console.log('list[index]["id"] = id; :>> ', (list[index]["id"] = id));
    setMember(list);
    console.log("member :>> ", member);
    setConsultantData({ ...consultantData, member: member });
    //list[index][obj.name] = value;
  };

  const handleRole = (obj, index) => {
    const { name, value } = obj;
    const list = [...member];
    list[index][name] = value;
    setMember(list);
    console.log("member :>> ", member);
    setConsultantData({ ...consultantData, member: member });
    //list[index][obj.name] = value;
  };
  return (
    <section className="section-manage-team-members">
      <h2 className="title-team-members">Client's team members</h2>
      <div className="table-title">
        <div className="title-name">Name</div>
        <div className="title-roles">Roles</div>
      </div>
      {consultants &&
        consultants.map((el) => {
          const { id, name } = el;
          return (
            <div key={id} className="name-role-settings">
              <p className="name-settings">{name}</p>
              <p className="role-settings">{el.Entity_User.userRole}</p>
              <RiDeleteBinLine
                className="delete-consultant-btn"
                size={20}
                onClick={() => {
                  deleteConsultantFromEntity(id);
                }}
              />
            </div>
          );
        })}

      <h3 className="title-add-members-settings">Add team members</h3>
      {member.map((el, index) => {
        const option =
          consultantNameFromAPI &&
          consultantNameFromAPI.map((el) => {
            return {
              value: el.name,
              label: el.name,
              name: "name",
              id: el.id,
            };
          });
        return (
          <div key={index}>
            <div className="select-div">
              <Select
                className="select-name"
                options={option}
                styles={customStyles}
                placeholder="Select Member"
                onChange={(obj) => handle(obj, index)}
                name="name"
              />
              <div className="member-role-settings">
                <Select
                  options={memberRole}
                  styles={customStyles2}
                  placeholder="Select Role"
                  onChange={(obj) => handleRole(obj, index)}
                  name="role"
                />
              </div>
              <button
                className="add-member"
                onClick={() => postConsultantInEntity()}
              >
                Add Member
              </button>
              {/* <img
                className="confirm-btn"
                src={confirmIcon}
                alt={confirmIcon}
                onClick={() => postConsultantInEntity()}
              /> */}
            </div>
          </div>
        );
      })}
      {/* <div className="btn-align-right">
        <button
          className="btn-client-settings-update"
          onClick={() => postConsultantInEntity()}
        >
          Add Member
        </button>
      </div> */}
    </section>
  );
};

export default TeamMembers;
