import axios from "axios";
import React, { useRef, useState } from "react";
import { useContext } from "react";
import { multiStepContext } from "../../context/stepContext";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatBytes } from "../../utils/utils";
import { displayFileExtensionImageByValue } from "../../utils/utils";

toast.configure();
const LastStep = () => {
  const { userData, submitData, fileData, setStep, contactData } =
    useContext(multiStepContext);

  console.log("userData.contactList :>> ", userData.contactsList);

  const history = useHistory();
  console.log("userData :>> ", userData);
  let btnRef = useRef();
  const submitClientInfo = async () => {
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
    }
    if (!userData.name && !userData.email) {
      toast.error("You have to add an email  and name for the contact", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    } else {
      const formData = new FormData();
      formData.append("name", userData.name);
      formData.append("email", userData.email);
      formData.append("role", "client");
      //userData.roadmapLink && formData.append("roadmap", userData.roadmapLink);
      formData.append("password", userData.password);

      fileData &&
        fileData.map((file) => {
          return formData.append(`files`, file);
        });
      userData.contactsList &&
        userData.contactsList.map((contact) => {
          console.log("contact :>> ", contact);
          return formData.append("contacts", JSON.stringify(contact));
        });
      userData.dataStudioLinksList &&
        userData.dataStudioLinksList.map((link) => {
          return formData.append(`dataStudioLinksList`, JSON.stringify(link));
        });
      userData.roadmapLinksList &&
        userData.roadmapLinksList.map((link) => {
          return formData.append(`roadmapLinksList`, JSON.stringify(link));
        });
      userData.member &&
        userData.member.map((consultant) => {
          return formData.append(`consultants`, JSON.stringify(consultant));
        });

      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/entity/create`, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          submitData();
          toast.success("Company added successfuly", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          history.push("/accueil/home");
        })
        .catch((error) => {
          toast.error("an error was occured please try again", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
          console.log("error :>> ", error);
        });
    }
    console.log("userData :>> ", userData);
  };

  return (
    <div className="last-step">
      <p className="four">4 -</p>
      <h4 className="title-step4">
        Summary and
        <br />
        confirmation
      </h4>
      <div className="container-last-div">
        <div className="title-edit">
          <h4>Your client's information</h4>
          <button
            className="btn-edit"
            onClick={() => {
              setStep(1);
            }}
          >
            Edit
          </button>
        </div>
        <div className="infos-client">
          <div className="name-info">
            <h6>Name :</h6> <p>{userData.name}</p>
          </div>
          <div className="email-info">
            <h6>Email :</h6>
            <p>{userData.email}</p>
          </div>
        </div>
        <div className="password-info">
          <h6>Password :</h6>
          <p>{userData.password}</p>
          <p style={{ color: "red" }}>
            Do not forget to copy the password, you will have to transmit it to
            your client.
          </p>
        </div>
        <div>
          <div className="title-edit">
            <h4>Your team members</h4>
            <button
              className="btn-edit"
              onClick={() => {
                setStep(2);
              }}
            >
              Edit
            </button>
          </div>
          <div className="table-title">
            <div className="title-name">Name</div>
            <div className="title-roles">Roles</div>
          </div>

          {userData.member ? (
            userData.member.map((el, index) => {
              return (
                <div className="name-role" key={index}>
                  <p className="name">{el.name}</p>
                  <p className="role">{el.role}</p>
                </div>
              );
            })
          ) : (
            <div>
              <span className="laststep-nodata-msg">
                No members in this team yet
              </span>
            </div>
          )}
        </div>
        {/*         <div>
          <div className="title-edit">
            <h4>Client's Documents</h4>
            <button
              className="btn-edit"
              onClick={() => {
                setStep(3);
              }}
            >
              Edit
            </button>
          </div>

          <div className="table-title-step5">
            <div className="title-file">Filename</div>
            <div className="title-type">Type</div>
            <div className="title-size">Size</div>
          </div>

          {fileData ? (
            fileData.map((el, index) => {
              return (
                <div className="file-settings" key={index}>
                  <p className="fileName">{el.name}</p>
                  <div className="type">
                    <div className="file-extension-laststep">
                      {displayFileExtensionImageByValue(el.type)}
                    </div>
                  </div>
                  <p className="size">{formatBytes(el.size)}</p>
                </div>
              );
            })
          ) : (
            <div>
              <span className="laststep-nodata-msg">
                No files for this client
              </span>
            </div>
          )}
        </div> */}

        <div>
          <div className="title-edit">
            <h4 className="title-docs">Dashboards</h4>
            <button
              className="btn-edit"
              onClick={() => {
                setStep(3);
              }}
            >
              Edit
            </button>
          </div>
          <div>
            <h2 className="title-datastudio">Data studio links</h2>
            <div>
              {userData.dataStudioLinksList ? (
                userData.dataStudioLinksList.map((el, index) => {
                  return (
                    <div>
                      <span>{el.title}</span>
                      <div className="datastudio-links" key={index}>
                        <a href={el.link} target="_blank" rel="noreferrer">
                          {el.link}
                        </a>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <span className="laststep-nodata-msg">
                    No dashboards for this client
                  </span>
                </div>
              )}
            </div>
          </div>

          <section className="roadmap-links">
            <div className="title-edit">
              <h4 className="title-docs">Roadmap</h4>
              <button
                className="btn-edit"
                onClick={() => {
                  setStep(3);
                }}
              >
                Edit
              </button>
            </div>
            <h2 className="title-datastudio">Roadmap link</h2>
            <div className="roadmap-display">
              <div>
                {userData.roadmapLinksList ? (
                  userData.roadmapLinksList.map((el, index) => {
                    return (
                      <div>
                        <span>{el.title}</span>
                        <div className="datastudio-links" key={index}>
                          <a href={el.link} target="_blank" rel="noreferrer">
                            {el.link}
                          </a>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <span className="laststep-nodata-msg">
                      No roadmaps for this client
                    </span>
                  </div>
                )}
              </div>
              {/* <a href={userData.roadmapLink} target="_blank" rel="noreferrer">
                {userData.roadmapLink && userData.roadmapLink}
              </a> */}
            </div>
          </section>
        </div>
        <div className="button-submit">
          <button
            className="btn-validate"
            ref={btnRef}
            onClick={submitClientInfo}
          >
            Create a new client
          </button>
        </div>
      </div>
    </div>
  );
};

export default LastStep;
