import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
const url = `${process.env.REACT_APP_API_URL}/api/reset/reset`;

toast.configure();
const ResetPassword = (props) => {
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [email, setEmail] = useState();
  const [token, setToken] = useState();
  const history = useHistory();

  useEffect(() => {
    const email = props.match.params.email;
    const token = props.match.params.token;
    console.log("email :>> ", email);
    console.log("token :>> ", token);
    setEmail(email);
    setToken(token);
  }, [props.match.params.email, props.match.params.token]);

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      await axios
        .post(url, { password: password, email: email, token: token })
        .then((res) => {
          console.log("res.data :>> ", res.data);
          setTimeout(() => {
            history.push("/connexion");
          }, 7000);
          toast.success(
            "Password change success you will be redirect to login page in few seconds",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            }
          );
        })
        .catch((err) => {
          console.log("err :>> ", err);
          toast.error("an error was occured please try again", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        });
    }
  };
  console.log("password :>> ", password);
  console.log("confirmePassword :>> ", confirmPassword);
  return (
    <section className="forget-password-section">
      <h1 className="header-password">Reset password</h1>
      <form onSubmit={handleSubmitPassword} className="form-reset">
        <label htmlFor="email">Password :</label>
        <input
          type="password"
          placeholder="Password"
          className="form-forget-input"
          onChange={(e) => setPassword(e.target.value)}
        />
        <label htmlFor="email">Confirm password :</label>
        <input
          type="password"
          placeholder="Confirm password"
          className="form-forget-input"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button className="submit-forget-reset" type="submit">
          Reset password
        </button>
      </form>
    </section>
  );
};

export default ResetPassword;
