import React from "react";

const YNcompany = () => {
  return (
    <div>
      <iframe
        className="iframe-notion"
        id="notion"
        title="notion"
        frameBorder="0"
        src="https://react-notion-x-54t2yilb5-m-bennai.vercel.app/0265184804eb43388231161927864e13"
      ></iframe>
    </div>
  );
};

export default YNcompany;
