import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import astro from "../assets/astro.jpg";
import { VscAdd } from "react-icons/vsc";
import Loading from "../components/Loading";
import { AuthContext } from "../auth/auth";
import { Redirect, useRouteMatch } from "react-router-dom";
import ClientLoginAccueil from "../components/ClientAccueil";
import { ClientInfosContext } from "../context/ClientInfosContext";
import ConsultantClientPage from "./ConsultantClientPage";
import { Loader } from "@mantine/core";
import instance from "../axios/global";

const AllClientsPage = () => {
  const urlAuth = `${process.env.REACT_APP_API_URL}/api/user/auth`;
  const { authState, setAuthState } = useContext(AuthContext);
  const { setClientName } = useContext(ClientInfosContext);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  useEffect(() => {
    setClientName("");
    axios
      .get(urlAuth, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          setAuthState({ ...authState, status: false });
        } else {
          localStorage.setItem("user", JSON.stringify(res.data));
          setAuthState({
            username: res.data.email,
            role: res.data.role,
            entityId: res.data.entityId,
            status: true,
          });
        }
      });
  }, []);

  useEffect(() => {
    const getAllClient = () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/entity/all`, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          if (res.data.error) {
            console.log("res.data.error :>> ", res.data.error);
          }
          setClients(res.data.allEntity);
          setLoading(false);
        })
        .catch((error) => {
          console.log("error :>> ", error);
          setLoading(true);
        });
    };
    getAllClient();

    return () => {
      setClients(null);
    };
  }, []);
  console.log("authState.role :>> ", authState.role);
  const userRole = () => {
    if (authState.role === "admin") {
      return (
        <div className="container">
          <div
            className="add-client-div"
            onClick={() => history.push("/create-client")}
          >
            <VscAdd size={55} />
            <span>Add new</span>
          </div>
          {loading ? (
            <Loader color="violet" size="xl" variant="dots" />
          ) : clients.length === 0 ? (
            <div>
              <h1>Pas encore de client ajouter</h1>
            </div>
          ) : (
            clients &&
            clients.map((client) => {
              const { id, name, image } = client;
              return (
                <div
                  key={id}
                  className="client-div"
                  onClick={() => history.push(`/accueil/client-file/${id}`)}
                >
                  <img src={image ? image : astro} alt={name} />
                  <h4>{client.name}</h4>
                </div>
              );
            })
          )}
        </div>
      );
    } else if (authState.role === "client") {
      return <ClientLoginAccueil />;
    } else if (authState.role === "consultant") {
      return <ConsultantClientPage />;
    }
  };

  if (!authState.status) {
    return <Redirect to="/" />;
  }
  return (
    <div className="accueil-all-client-page ">
      {/* <NavAccueil /> */}
      {userRole()}
    </div>
  );
};

export default AllClientsPage;
