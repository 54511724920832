import React from "react";

const Loading = () => {
  return (
    <div id="ball">
      <div id="ball1" className="balls"></div>
      <div id="ball2" className="balls"></div>
      <div id="ball3" className="balls"></div>
    </div>
  );
};

export default Loading;
