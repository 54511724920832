import React from "react";
import { AiFillFolderOpen } from "react-icons/ai";

const FilesFolder = ({ filterItem, category }) => {
  return (
    <div className="folder-component">
      <h2>Folders</h2>
      <div className="folder-button-container">
        {category.map((category, index) => {
          return (
            <button
              className="category-button"
              key={index}
              onClick={() => {
                filterItem(category);
              }}
            >
              <div className="container-span-folder">
                <span>
                  <AiFillFolderOpen size={18} />
                </span>
                <span>{category}</span>
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default FilesFolder;
