import React, { useState } from "react";
import astro from "../../assets/astro.jpg";
import axios from "axios";
import { toast } from "react-toastify";
import uploadIcon from "../../assets/upload-basic-infos.svg";
import updateIcon from "../../assets/update-icon.svg";
import Modal from "react-modal";
import { GrFormClose } from "react-icons/gr";
import { useHistory } from "react-router-dom";

const BasicInformation = ({
  image,
  name,
  id,
  setImage,
  email,
  setName,
  consultants,
  files,
}) => {
  const [file, setFile] = useState(undefined);
  const [entityName, setEntityName] = useState();
  const [userContactEmail, setUserContactEmail] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [nameToDelete, setNameToDelete] = useState();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", file);
    formData.append("id", id);

    await axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/entity/update/image/${id}`,
        formData,
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setTimeout(() => {
          const getImage = () => {
            axios
              .get(`${process.env.REACT_APP_API_URL}/api/entity/${id}`, {
                headers: {
                  accessToken: localStorage.getItem("accessToken"),
                },
              })
              .then((res) => {
                setImage(res.data.entity.image);
              })
              .catch((err) => {
                console.log("err :>> ", err);
              });
          };
          getImage();
        }, 1000);
      })
      .catch((error) => {
        toast.error("an error was occured", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        console.log("error :>> ", error);
      });
    /* refreshPage(); */
  };

  const handleSubmitName = (e) => {
    console.log("entityName :>> ", entityName);
    e.preventDefault();
    axios
      .put(`${process.env.REACT_APP_API_URL}/api/entity/update/${id}`, {
        name: entityName,
      })
      .then((res) => {
        console.log("res.data :>> ", res.data);
        toast.success("name update success", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setTimeout(() => {
          const getEntityName = () => {
            axios
              .get(`${process.env.REACT_APP_API_URL}/api/entity/${id}`, {
                headers: {
                  accessToken: localStorage.getItem("accessToken"),
                },
              })
              .then((res) => {
                console.log("j'arrive a venir ici");
                console.log(
                  "res from getEntityName from file upload :>> ",
                  res
                );
                setName(res.data.entity.name);
              })
              .catch((err) => {
                console.log("err :>> ", err);
              });
          };
          getEntityName();
        }, 300);
      })
      .catch((error) => {
        toast.error("an error was occured", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        console.log("error :>> ", error);
      });
  };

  const handleSubmitEmail = (e) => {
    e.preventDefault();
    axios
      .put(`${process.env.REACT_APP_API_URL}/api/entity/update/email/${id}`, {
        email: userContactEmail,
      })
      .then((res) => {
        console.log("res.data :>> ", res.data);
        toast.success("email update success", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setTimeout(() => {
          const getUserEmail = () => {
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/api/entity/${id}`,

                {
                  headers: {
                    accessToken: localStorage.getItem("accessToken"),
                  },
                }
              )
              .then((res) => {
                console.log("j'arrive a venir ici");
                console.log("res from getUserEmail from file upload :>> ", res);
                setUserContactEmail(res.data.entity.contact);
              })
              .catch((err) => {
                console.log("err :>> ", err);
              });
          };
          getUserEmail();
        }, 300);
      })
      .catch((error) => {
        toast.error("an error was occured", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        console.log("error :>> ", error);
      });
  };

  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }
  const customStylesModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "55rem",
      height: "33rem",
      padding: "45px",
    },
  };
  const handleDeleteEntity = (e) => {
    //console.log("consultants :>> ", consultants);
    e.preventDefault();
    const formData = new FormData();
    formData.append(`consultants`, JSON.stringify(consultants));
    if (name === nameToDelete) {
      axios
        .delete(`${process.env.REACT_APP_API_URL}/api/entity/delete/${id}`, {
          data: { consultants: consultants, files: files },
        })
        .then((res) => {
          console.log("res.dara :>> ", res.data);
          toast.success("client delete success", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
          history.push("/accueil/home");
        })
        .catch((error) => {
          console.log("error :>> ", error);
          toast.error("an error was occured", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
        });
    }
  };
  return (
    <section className="basic-info-section">
      <h4 className="change-logo-settings">Change Logo</h4>
      <div className="client-logo-container">
        <img className="client-logo" src={image ? image : astro} alt="logo" />
        <div className="button-description-container">
          <form
            action=""
            method="post"
            onSubmit={handleSubmit}
            className="form-input-logo"
          >
            <div className="input-settings-div">
              {/*    {selectedImage && (
                  <div style={styles.preview}>
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      style={styles.image}
                      alt="Thumb"
                    />
                  </div>
                )} */}
              <div className="upload-logo-and-input">
                <img src={uploadIcon} alt={uploadIcon} />
                <input
                  id="choose-file"
                  className="custom-file-input"
                  type="file"
                  name="image"
                  accept="image/*"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                    //imageChange(e);
                  }}

                  //className="btn-client-settings-upload"
                />
              </div>

              <div className="button-update-settings">
                <h6 className="description-logo-settings">
                  Max File Size: 1MB
                  <br />
                  200x200 px, PNG/JPG format
                </h6>
                <button className="btn-upload-logo" type="submit">
                  Upload Logo
                </button>
                {/* <img
                  className="icon-manage-client-confirm"
                  src={confirmIcon}
                  alt={confirmIcon}
                  type="submit"
                /> */}
              </div>
            </div>
          </form>
        </div>
      </div>

      <section className="section-basic-info-settings">
        {/* <div className="display-name-email-settings">
          <span>{name}</span>
          <span className="email-display">{email}</span>
        </div> */}
        <div>
          <h3 className="title-change-info-settings">Change informations</h3>
        </div>
        <div className="input-name-email-settings">
          <form className="form-input-name-email" onSubmit={handleSubmitName}>
            <div className="input-name-settings">
              <label htmlFor="name">Name</label>
              <div className="input-align-button-update">
                <input
                  className="input-first-step"
                  id="name"
                  type="name"
                  name="name"
                  placeholder={name}
                  onChange={(e) => setEntityName(e.target.value)}
                />
                <img
                  className="icon-manage-client-update"
                  src={updateIcon}
                  alt={updateIcon}
                  onClick={handleSubmitName}
                />
              </div>
            </div>
            {/* <div className="btn-align-right">
              <button className="btn-client-settings-update">
                Update Name
              </button>
            </div> */}
          </form>
          <form className="form-input-name-email">
            <div className="input-email-settings">
              <label>Email</label>
              <div className="input-align-button-update">
                <input
                  className="input-first-step"
                  id="email"
                  type="email"
                  name="email"
                  placeholder={email}
                  onChange={(e) => setUserContactEmail(e.target.value)}
                />
                <img
                  className="icon-manage-client-update"
                  src={updateIcon}
                  alt={updateIcon}
                  onClick={handleSubmitEmail}
                />
              </div>
            </div>
            {/* <div className="btn-align-right">
              <button className="btn-client-settings-update">
                Update Email
              </button>
            </div> */}
          </form>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStylesModal}
          contentLabel="Modal"
        >
          <section className="section-modal-addfiles">
            <div className="header-modal-delete-entity">
              <h3>Are you sure absolutely sure?</h3>
              <div className="close-modal">
                <div className="close-modal-button">
                  <GrFormClose
                    style={{ cursor: "pointer" }}
                    size={15}
                    className="cross-modal"
                    onClick={closeModal}
                  />
                </div>
              </div>
            </div>
            <p>
              This action cannot be undone. This will permanently delete the{" "}
              {name} project, files, and remove all associations.
            </p>
            <p className="confirm-delete-entity-msg">
              Please type "{name}" to confirm.
            </p>

            <form action="" onSubmit={handleDeleteEntity}>
              <div className="input-delete-btn-modal-div">
                <input
                  className="input-modal-delete-entity"
                  type="text"
                  placeholder="Name of client to delete"
                  onChange={(e) => setNameToDelete(e.target.value)}
                />
                <div className="div-last-delete-btn">
                  <button className="last-delete-btn-entity">
                    I understand the consequences, delete this project
                  </button>
                </div>
              </div>
            </form>
          </section>
        </Modal>

        <button
          style={{ marginTop: "4rem" }}
          className="btn-delete-entity"
          onClick={openModal}
        >
          Delete Client
        </button>
      </section>
    </section>
  );
};

export default BasicInformation;
