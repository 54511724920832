import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

toast.configure();
const url = `${process.env.REACT_APP_API_URL}/api/reset`;
const ForgetPassword = () => {
  const [email, setEmail] = useState();
  const history = useHistory();
  console.log("email :>> ", email);
  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    await axios
      .post(url, { email: email })
      .then((res) => {
        setTimeout(() => {
          history.push("/connexion");
        }, 10000);
        toast.success(
          "Your just receive an email to reset your password please check your spam",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 7000,
          }
        );
        console.log("res.data :>> ", res.data);
      })
      .catch((err) => {
        console.log("err :>> ", err);
        toast.error("an error was occured please try again", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      });
  };

  return (
    <section className="forget-password-section">
      <h1 className="header-password">Reset your password</h1>
      <form onSubmit={handleSubmitEmail} className="form-forget">
        <label htmlFor="email">Email :</label>
        <input
          id="email"
          type="email"
          className="form-forget-input"
          placeholder="Email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          required
        />
        <button className="submit-forget-reset" type="submit">
          Reset password
        </button>
      </form>
    </section>
  );
};

export default ForgetPassword;
