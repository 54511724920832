import React, { useContext, useState } from "react";
import { format } from "date-fns";
import { displayFileExtensionImage } from "../../../utils/utils";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import {
  AiOutlineDownload,
  AiOutlineDelete,
  AiOutlineTags,
} from "react-icons/ai";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import Select from "react-select";
import { AiOutlineTag } from "react-icons/ai";
import { toast } from "react-toastify";
import axios from "axios";
import { AuthContext } from "../../../auth/auth";
toast.configure();

const FilesList = ({
  files,
  downLoadFileGcp,
  removeFile,
  setFiles,
  entityId,
}) => {
  const { authState } = useContext(AuthContext);

  const handle = (e, id) => {
    console.log("obj :>> ", e);
    console.log("id :>> ", e);

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/docs/update/${id}`,
        { tags: e },
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      )
      .then((res) => {
        console.log("res >> ", res.data);
        toast.success(res.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setTimeout(() => {
          const getFiles = () => {
            console.log("entityId :>> ", entityId);
            axios
              .get(`${process.env.REACT_APP_API_URL}/api/entity/${entityId}`, {
                headers: {
                  accessToken: localStorage.getItem("accessToken"),
                },
              })
              .then((res) => {
                console.log("res from getImage from file upload :>> ", res);
                setFiles(res.data.entity.docs);
                console.log("res.data :>> ", res.data.entity.image);
              })
              .catch((err) => {
                console.log("err :>> ", err);
              });
          };
          getFiles();
        }, 400);
      })
      .catch((err) => {
        console.log("err :>> ", err);
        toast.error("an error was occured", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      });
    //list[index][obj.name] = value;
  };
  return (
    <div className="filelist-component">
      <h2>Files</h2>

      <div className="file-container">
        {files.map((file, index) => {
          const {
            id,
            title,
            tags,
            createdAt,
            file_mimetype,
            gcpName,
            file_path,
          } = file;
          const newDate = format(new Date(createdAt), "dd/MM/yyyy");

          return (
            <article className="file-card" key={index}>
              <div className="card-elements">
                <div className="card-header">
                  <span>{newDate}</span>
                  <>
                    <Menu>
                      <MenuButton lineHeight="1.4" fontSize="30px">
                        ...
                      </MenuButton>
                      <MenuList>
                        {gcpName === "link" ? (
                          <MenuItem>
                            <a
                              href={file_path}
                              //style={{ color: "black", marginTop: "4px" }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              View
                            </a>
                          </MenuItem>
                        ) : (
                          <MenuItem
                            //  icon={<AiOutlineDownload />}
                            onClick={() => {
                              downLoadFileGcp(gcpName, title, file_mimetype);
                            }}
                          >
                            Download
                          </MenuItem>
                        )}
                        {authState.role === "client" ? (
                          ""
                        ) : (
                          <>
                            <MenuItem
                              onClick={() => {
                                removeFile(id, gcpName);
                              }}
                              // icon={<AiOutlineDelete />}
                            >
                              Delete
                            </MenuItem>
                          </>
                        )}
                        {authState.role === "client" ? (
                          ""
                        ) : (
                          <>
                            <MenuGroup title="Tag" type="checkbox">
                              <MenuItem
                                value="Administratif"
                                onClick={(e) => handle(e.target.value, id)}
                              >
                                Administratif
                              </MenuItem>
                              <MenuItem
                                value="Analytics"
                                onClick={(e) => handle(e.target.value, id)}
                              >
                                Analytics
                              </MenuItem>
                              <MenuItem
                                value="Content"
                                onClick={(e) => handle(e.target.value, id)}
                              >
                                Content
                              </MenuItem>
                              <MenuItem
                                value="CRO"
                                onClick={(e) => handle(e.target.value, id)}
                              >
                                CRO
                              </MenuItem>
                              <MenuItem
                                value="Multi channel"
                                onClick={(e) => handle(e.target.value, id)}
                              >
                                Multi channel
                              </MenuItem>
                              <MenuItem
                                value="SEA"
                                onClick={(e) => handle(e.target.value, id)}
                              >
                                SEA
                              </MenuItem>
                              <MenuItem
                                value="SEO"
                                onClick={(e) => handle(e.target.value, id)}
                              >
                                SEO
                              </MenuItem>
                            </MenuGroup>
                          </>
                        )}
                      </MenuList>
                    </Menu>
                  </>
                </div>
                <div className="card-body">
                  {displayFileExtensionImage(file)}
                </div>
              </div>
              <div className="card-footer">
                <p>{title}</p>
              </div>
            </article>
          );
        })}
      </div>
    </div>
  );
};

export default FilesList;
