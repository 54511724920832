import { React, createContext, useState } from "react";

export const ClientInfosContext = createContext();

const ClientInfosContextProvider = ({ children }) => {
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [clientImage, setClientImage] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientId, setClientId] = useState("");
  const [showClientNav, setShowClientNav] = useState(false);
  const [showSettingClient, setShowSettingClient] = useState(false);
  const [email, setEmail] = useState(null);
  const [consultantId, setConsultantId] = useState(null);

  return (
    <ClientInfosContext.Provider
      value={{
        image,
        setImage,
        name,
        setName,
        clientImage,
        setClientImage,
        clientName,
        setClientName,
        clientId,
        setClientId,
        showClientNav,
        setShowClientNav,
        setShowSettingClient,
        showSettingClient,
        email,
        setEmail,
        consultantId,
        setConsultantId,
      }}
    >
      {children}
    </ClientInfosContext.Provider>
  );
};

export default ClientInfosContextProvider;
