import React from "react";
import { useState } from "react";
import astro from "../assets/astro.jpg";
import uploadIcon from "../assets/upload-basic-infos.svg";

import { useContext } from "react";
import { ClientInfosContext } from "../context/ClientInfosContext";
import axios from "axios";
import { toast } from "react-toastify";
toast.configure();

const MySettings = () => {
  const { image, name, email, consultantId } = useContext(ClientInfosContext);
  const [file, setFile] = useState();
  const [newName, setNewName] = useState();
  const [newEmail, setNewEmail] = useState();
  const [password, setPassword] = useState();
  console.log("file :>> ", file);
  const handleSubmitImgage = (e) => {
    console.log("e :>> ", e);
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", file);
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/user/update/image/${
          consultantId && consultantId
        }`,
        formData
      )
      .then((res) => {
        console.log("res.data :>> ", res.data);
        toast.success("Image Update success", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      })
      .catch((error) => {
        console.log("error :>> ", error);
        toast.error("an error was occured", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      });
  };
  console.log("consultantId :>> ", consultantId);
  const handleSubmitName = (e) => {
    e.preventDefault();
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/user/update/name/${
          consultantId && consultantId
        }`,
        { name: newName }
      )
      .then((res) => {
        console.log("res.data :>> ", res.data);
        toast.success("Name change success", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      })
      .catch((error) => {
        console.log("error :>> ", error);
        toast.error("an error was occured", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      });
  };
  console.log("newEmail :>> ", newEmail);
  const handleSubmitEmail = (e) => {
    console.log("newEmail :>> ", newEmail);
    e.preventDefault();
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/user/update/email/${
          consultantId && consultantId
        }`,
        { email: newEmail }
      )
      .then((res) => {
        console.log("res.data :>> ", res.data);
        toast.success("Email change success", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      })
      .catch((error) => {
        console.log("error :>> ", error);
        toast.error("an error was occured", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      });
  };
  console.log("image :>> ", image);

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    await axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/user/update/password/${
          consultantId && consultantId
        }`,
        { password: password }
      )
      .then((res) => {
        console.log("res.date :>> ", res.data);
        toast.success("Password change success", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.log("err ici:>> ", err);
        toast.error("an error was occured", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      });
  };
  return (
    <section className="section-mysettings-consultant">
      <div className="content-mysettings-consutant">
        <div className="div-mysettings-consultant">
          <h1 className="title-mysettings-consultant">My Settings</h1>
          <h4 className="change-logo-settings">Update my photo</h4>
        </div>
        <div className="client-logo-container">
          {/* <img className="client-logo" src={image ? image : astro} alt="logo" /> */}
          <img className="client-logo" src={image ? image : astro} alt="logo" />
          <div className="button-description-container">
            <form
              action=""
              method="post"
              onSubmit={handleSubmitImgage}
              className="form-input-logo"
            >
              <div className="input-settings-div">
                {/*    {selectedImage && (
                  <div style={styles.preview}>
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      style={styles.image}
                      alt="Thumb"
                    />
                  </div>
                )} */}
                <div className="upload-logo-and-input">
                  <img src={uploadIcon} alt={uploadIcon} />
                  <input
                    id="choose-file"
                    className="custom-file-input"
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}

                    //className="btn-client-settings-upload"
                  />
                </div>

                <div className="button-update-settings">
                  <h6 className="description-logo-settings">
                    Max File Size: 1MB
                    <br />
                    200x200 px, PNG/JPG format
                  </h6>
                  <button className="btn-upload" type="submit">
                    Update Photo
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <section className="section-basic-info-settings">
          {/* <div className="display-name-email-settings">
          <span>{name}</span>
          <span className="email-display">{email}</span>
        </div> */}
          <div>
            <h3 className="title-change-info-settings">My infos</h3>
          </div>
          <div className="input-name-email-settings">
            <form className="form-input-name-email" onSubmit={handleSubmitName}>
              {/* <form className="form-input-name-email" onSubmit={handleSubmitName}></form> */}
              <div className="input-name-settings">
                <label htmlFor="name">Name</label>
                <div className="input-align-button-update">
                  <input
                    className="input-first-step"
                    id="name"
                    type="name"
                    name="name"
                    placeholder={name}
                    onChange={(e) => setNewName(e.target.value)}
                  />
                  {/* <img src={updateIcon} alt="astronaut" /> */}
                  <button className="btn-update" type="submit">
                    Update name
                  </button>
                </div>
              </div>
              {/* <div className="btn-align-right">
              <button className="btn-client-settings-update">
                Update Name
              </button>
            </div> */}
            </form>
            <form
              className="form-input-name-email"
              onSubmit={handleSubmitEmail}
            >
              {/* <form className="form-input-name-email" onSubmit={handleSubmitEmail}> */}
              <div className="input-email-settings">
                <label>Email</label>
                <div className="input-align-button-update">
                  <input
                    className="input-first-step"
                    id="email"
                    type="email"
                    name="email"
                    placeholder={email}
                    onChange={(e) => setNewEmail(e.target.value)}
                  />
                  {/*                 <img src={updateIcon} alt={updateIcon} /> */}
                  <button className="btn-update" type="submit">
                    Update email
                  </button>
                </div>
              </div>
              {/* <div className="btn-align-right">
              <button className="btn-client-settings-update">
                Update Email
              </button>
            </div> */}
            </form>
            <form
              className="form-input-name-email"
              onSubmit={handleSubmitPassword}
            >
              {/* <form className="form-input-name-email" onSubmit={handleSubmitEmail}> */}
              <div className="input-email-settings">
                <label htmlFor="password">Password</label>
                <div className="input-align-button-update">
                  <input
                    className="input-first-step"
                    id="password"
                    type="password"
                    name="password"
                    placeholder="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button className="btn-update" type="submit">
                    Update password
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </section>
  );
};

export default MySettings;
