import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NotionRenderer } from "react-notion";

const NewsPost = () => {
  const [blockMap, setBlockMap] = useState({});
  const { newsTitle } = useParams();

  console.log("seoTitle :>> ", newsTitle);
  async function NewsNameToId(newsSlug) {
    const newsDatabase = await fetch(
      "https://notion-api.splitbee.io/v1/table/c4b908bdb4f0418e9ec7e8a55b56e267"
    ).then((res) => res.json());
    console.log("NEWSDatabase :>> ", newsDatabase);

    try {
      const news = newsDatabase.filter((news) => news.slug === newsSlug).pop();
      console.log("seo.slug :>> ", news.slug);
      return news.id;
    } catch {
      return null;
    }
  }

  useEffect(() => {
    // Create an scoped async function in the hook
    async function getBlockId() {
      const NewsId = await NewsNameToId(newsTitle);

      const newsData = await fetch(
        "https://notion-api.splitbee.io/v1/page/" + NewsId
      ).then((res) => res.json());

      setBlockMap(newsData);
      console.log("seodata :>> ", newsData);
      console.log("seoTitle :>> ", newsTitle);
      console.log("seoId :>> ", NewsId);
      console.log("blockMap :>> ", blockMap);
    }
    getBlockId();
  }, [newsTitle]);

  return (
    <div>
      <NotionRenderer fullPage blockMap={blockMap} />
    </div>
  );
};

export default NewsPost;
