export const consultantName = [
  {
    value: "Alexandre Antar",
    label: "Alexandre Antar",
    name: "name",
  },
  { value: "Ninon Larcher", label: "Ninon Larcher", name: "name" },
  {
    value: "Christophe Leroy",
    label: "Christophe Leroy",
    name: "name",
  },
  {
    value: "Clément Boccardi",
    label: "Clément Boccardi",
    name: "name",
  },
  { value: "Benoit Chanu", label: "Benoit Chanu", name: "name" },
  {
    value: "Romain Tournier",
    label: "Romain Tournier",
    name: "name",
  },
  {
    value: "Valentin Hullot-Wilm",
    label: "Valentin Hullot-Wilm",
    name: "name",
  },
  {
    value: "Victor Bourlon",
    label: "Victor Bourlon",
    name: "name",
  },
  {
    value: "Juliana Allèbe",
    label: "Juliana Allèbe",
    name: "name",
  },
  {
    value: "Bruno Arduin",
    label: "Bruno Arduin",
    name: "name",
  },
  {
    value: "Jérôme Duprat",
    label: "Jérôme Duprat",
    name: "name",
  },
  {
    value: "Jemal Sevir",
    label: "Jemal Sevir",
    name: "name",
  },
  {
    value: "Reda Ghazzali",
    label: "Reda Ghazzali",
    name: "name",
  },
  {
    value: "Mathieu Domingues",
    label: "Mathieu Domingues",
    name: "name",
  },
  {
    value: "Yajing Hou",
    label: "Yajing Hou",
    name: "name",
  },
  {
    value: "Aude Repussard",
    label: "Aude Repussard",
    name: "name",
  },
  {
    value: "Kamel Bennaï",
    label: "Kamel Bennaï",
    name: "name",
  },
  {
    value: "Pauline Brissard",
    label: "Pauline Brissard",
    name: "name",
  },
  {
    value: "Thomas Oltra Llacer",
    label: "Thomas Oltra Llacer",
    name: "name",
  },
  {
    value: "Sophia Allou",
    label: "Sophia Allou",
    name: "name",
  },
  {
    value: "Vincent Thijssens",
    label: "Vincent Thijssens",
    name: "name",
  },
  {
    value: "Romain Jacqz",
    label: "Romain Jacqz",
    name: "name",
  },
  {
    value: "Jérôme Hoerlé",
    label: "Jérôme Hoerlé",
    name: "name",
  },
  {
    value: "Emmanuel Laveran",
    label: "Emmanuel Laveran",
    name: "name",
  },
  {
    value: "Vincent Turlotte",
    label: "Vincent Turlotte",
    name: "name",
  },
  {
    value: "Jordan Chevalier-Bounaud",
    label: "Jordan Chevalier-Bounaud",
    name: "name",
  },
  {
    value: "Arthur Quemere",
    label: "Arthur Quemere",
    name: "name",
  },
  {
    value: "Clarisse Ochs",
    label: "Clarisse Ochs",
    name: "name",
  },
  {
    value: "Yannick Michellod",
    label: "Yannick Michellod",
    name: "name",
  },
  {
    value: "Meriem Ben Said",
    label: "Meriem Ben Said",
    name: "name",
  },
  {
    value: "Laure Thomas",
    label: "Laure Thomas",
    name: "name",
  },
  {
    value: "Aina Mangin",
    label: "Aina Mangin",
    name: "name",
  },
  {
    value: "Pauline Sire",
    label: "Pauline Sire",
    name: "name",
  },
  {
    value: "Vincent Mesnard",
    label: "Vincent Mesnard",
    name: "name",
  },
  {
    value: "Clément Breuille",
    label: "Clément Breuille",
    name: "name",
  },
  {
    value: "Pauline Pecorini",
    label: "Pauline Pecorini",
    name: "name",
  },
  {
    value: "David Smilovitz",
    label: "David Smilovitz",
    name: "name",
  },
  {
    value: "Yaël Petit",
    label: "Yaël Petit",
    name: "name",
  },
  {
    value: "Victoria Jimenez",
    label: "Victoria Jimenez",
    name: "name",
  },
  {
    value: "Victor Charron",
    label: "Victor Charron",
    name: "name",
  },
  {
    value: "Edwin Vignot",
    label: "Edwin Vignot",
    name: "name",
  },
  {
    value: "Delphine Rault",
    label: "Delphine Rault",
    name: "name",
  },
  {
    value: "Harold Rouanet",
    label: "Harold Rouanet",
    name: "name",
  },
  {
    value: "Andrea Diodati",
    label: "Andrea Diodati",
    name: "name",
  },
  {
    value: "Myriam Hadji",
    label: "Myriam Hadji",
    name: "name",
  },
  {
    value: "Steffie Kronek",
    label: "Steffie Kronek",
    name: "name",
  },
];
export const consultantRole = [
  {
    value: "SEO Consultant",
    label: "SEO Consultant",
    name: "role",
  },
  {
    value: "SEA Consultant",
    label: "SEA Consultant",
    name: "role",
  },
  {
    value: "Analytics Consultant",
    label: "Analytics Consultant",
    name: "role",
  },
  {
    value: "CRO Consultant",
    label: "CRO Consultant",
    name: "role",
  },
  {
    value: "Content Strategist",
    label: "Content Strategist",
    name: "role",
  },
  {
    value: "Client Lead",
    label: "Client Lead",
    name: "role",
  },
  {
    value: "CRM Consultant",
    label: "CRM Consultant",
    name: "role",
  },
  {
    value: "Data / Analytics Consultant ",
    label: "Data / Analytics Consultant ",
    name: "role",
  },
  {
    value: "CRO Consultant / Client Lead",
    label: "CRO Consultant / Client Lead",
    name: "role",
  },
  {
    value: "SEA Consultant  / Client Lead",
    label: "SEA Consultant  / Client Lead",
    name: "role",
  },
  {
    value: "SEO Consultant / Client Lead",
    label: "SEO Consultant / Client Lead",
    name: "role",
  },
  {
    value: "Content Strategist / Client Lead",
    label: "Content Strategist / Client Lead",
    name: "role",
  },
  {
    value: "CRM Consultant / Client Lead",
    label: "CRM Consultant / Client Lead",
    name: "role",
  },
  {
    value: "Analytics / Data Consultant / Client Lead",
    label: "Analytics / Data Consultant / Client Lead",
    name: "role",
  },
];

export const userJob = [
  {
    value: "Consultante marketing digital",
    label: "Consultante marketing digital",
    name: "job",
  },
  {
    value: "Responsable administrative et financiere",
    label: "Responsable administrative et financiere",
    name: "job",
  },
  { value: "Consultant SEO", label: "Consultant SEO", name: "job" },
  { value: "Founder @ Z51", label: "Founder @ Z51", name: "job" },
  { value: "Consultant junior", label: "Consultant junior", name: "job" },
  { value: "Consultante junior", label: "Consultante junior", name: "job" },
  { value: "Alternant", label: "Alternant", name: "job" },
  { value: "Founder", label: "Founder", name: "job" },
  { value: "Squad leader", label: "Squad leader", name: "job" },
  { value: "One of twobirds", label: "One of twobirds", name: "job" },
  {
    value: "Consultante SEA junior",
    label: "Consultante SEA junior",
    name: "job",
  },
  { value: "COO", label: "COO", name: "job" },
  { value: "Stagiaire", label: "Stagiaire", name: "job" },
  {
    value: "Consultant marketing digital",
    label: "Consultant marketing digital",
    name: "job",
  },
  { value: "Alternante", label: "Alternante", name: "job" },
  { value: "Partner", label: "Partner", name: "job" },
  {
    value: "Consultante Data/Analytics",
    label: "Consultante Data/Analytics",
    name: "job",
  },
  { value: "Head of Sales", label: "Head of Sales", name: "job" },
  { value: "Content strategist", label: "Content strategist", name: "job" },
  { value: "Consultante SEO", label: "Consultante SEO", name: "job" },
  { value: "Business Developer", label: "Business Developer", name: "job" },
  { value: "Consultant SEO / CRO", label: "Consultant SEO / CRO", name: "job" },
  { value: "SEA specialist", label: "SEA specialist", name: "job" },
  { value: "Consultante UX / CRO", label: "Consultante UX / CRO", name: "job" },
  {
    value: "Alternant Marketing/SEO",
    label: "Alternant Marketing/SEO",
    name: "job",
  },
  {
    value: "Consultant Marketing Digital Senior",
    label: "Consultant Marketing Digital Senior",
    name: "job",
  },
  { value: "Paid specialist", label: "Paid specialist", name: "job" },
  {
    value: "Consultant tracking Data",
    label: "Consultant tracking Data",
    name: "job",
  },
  { value: "Founder @ Weyu", label: "Founder @ Weyu", name: "job" },
  {
    value: "Apprentie comptable et financier",
    label: "Apprentie comptable et financier",
    name: "job",
  },
  { value: "Founder @ Juno", label: "Founder @ Juno", name: "job" },
  { value: "Business developer", label: "Business developer", name: "job" },
  {
    value: "Directeur de clientele",
    label: "Directeur de clientele",
    name: "job",
  },
  { value: "Sales", label: "Sales", name: "job" },
  { value: "Consultante Paid", label: "Consultante Paid", name: "job" },
];
