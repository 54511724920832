import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import sendmail from "../../assets/sendmail.svg";
import { ClientInfosContext } from "../../context/ClientInfosContext";
import astro from "../../assets/astro.jpg";

const ClientTeam = () => {
  const [consultants, setConsultant] = useState([]);
  const { clientId, setShowClientNav } = useContext(ClientInfosContext);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/entity/${clientId}`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        console.log("res :>> ", res);
        /* setShowClientNav(true); */
        setConsultant(res.data.entity.user);
      })
      .catch((error) => {
        console.log("error :>> ", error);
      });

    return () => {
      setConsultant();
    };
  }, [clientId]);

  // const ButtonMailto = ({ mailto, label }) => {
  //     return (
  //         <Link
  //             to='#'
  //             onClick={(e) => {
  //                 window.location = mailto;
  //                 e.preventDefault();
  //             }}
  //         >
  //             {label}
  //         </Link>
  //     );
  // };

  return (
    <section className="client-myteam-section">
      <div className="div-myteam-container">
        <h1 className="title-myteam-client">My Team</h1>

        <section className="grid-myteam-container">
          {consultants &&
            consultants.map((el) => {
              const { id, name, image, email } = el;
              console.log("elementt", el);
              return (
                <div key={id} className="consultant-container">
                  <figure>
                    <img src={image ? image : astro} alt="" />
                    <figcaption>{name}</figcaption>
                  </figure>
                  <p className="role-settings">{el.Entity_User.userRole}</p>
                  <div className="div-button-sendmail">
                    <button
                      onClick={() => (window.location = `mailto:${email}`)}
                    >
                      <span>{email}</span>
                      {/* <img
                        className="sendmail-icon"
                        src={sendmail}
                        alt={sendmail}
                      /> */}
                    </button>
                  </div>
                </div>
              );
            })}
        </section>
      </div>
    </section>
  );
};

export default ClientTeam;
