import { BrowserRouter, Route, Switch } from "react-router-dom";
import Connexion from "./components/Connexion";
import { AuthContext } from "./auth/auth";
import { React, useEffect, useState } from "react";

import Accueil from "./components/Accueil";
import DashBoard from "./components/Dashboard";
import StepContext from "./context/stepContext";
import Modal from "react-modal";
import ClientInfosContextProvider from "./context/ClientInfosContext";
import ForgetPassword from "./components/ForgetPassword";
import ResetPassword from "./components/ResetPassword";
import ProtectedRoute from "./components/ProtectedRoute";

Modal.setAppElement("#root");

function App() {
  const [authState, setAuthState] = useState({
    username: "",
    role: "",
    entityId: "",
    status: false,
  });

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setAuthState({ ...authState, status: true });
    }
  }, []);
  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <ClientInfosContextProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Connexion} />
            <Route path="/forget" component={ForgetPassword} />
            <Route path="/reset/:email/:token" component={ResetPassword} />
            <Route path="/accueil" component={Accueil} />
            {/* <ProtectedRoute path="/accueil" component={Accueil} /> */}
            {/*           <Route path="/client-page/:id" component={ClientComponent} />
          <Route path="/client-file" component={ClientFile} /> */}
            {/*           <Route path="/client-page-dashboard" component={ClientDasboard} /> */}
            <StepContext>
              {/* <ProtectedRoute path="/create-client" component={DashBoard} /> */}
              <Route path="/create-client" component={DashBoard} />
            </StepContext>
          </Switch>
        </BrowserRouter>
      </ClientInfosContextProvider>
    </AuthContext.Provider>
  );
}

export default App;
