import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { multiStepContext } from "../../context/stepContext";
import { generatePassword } from "../../utils/utils";
import ReactTooltip from "react-tooltip";
import { BsQuestionCircle } from "react-icons/bs";
import axios from "axios";
import { toast } from "react-toastify";
import crossmark from "../../assets/cross-mark.svg";

toast.configure();
const FirstStep = () => {
  const { setStep, userData, setUserData } = useContext(multiStepContext);
  const [nameInput, setNameInput] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [exist, setExist] = useState(true);
  const [contactList, setContactList] = useState([
    {
      contact: "",
      role: "",
    },
  ]);

  const handleAddContact = () => {
    setContactList([...contactList, { contact: "", role: "" }]);
  };

  const next = () => {
    const email = document.getElementById("email");
    const name = document.getElementById("name");
    const password = document.getElementById("password");

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const PASSWORD_REGEX_1 = /^[A-Za-z0-9]\w{5,}$/;

    if (name.value === "") {
      name.classList.add("border-red");
      setNameError("Please enter a valid client name");
    } else if (name.value.length > 25) {
      name.classList.add("border-red");
      setNameError("Name too long");
    } else if (email.value === "") {
      email.classList.add("border-red");
      setEmailError("Please enter your client email");
    } else if (!emailRegex.test(email.value)) {
      setEmailError("Please enter a valid email");
    } else if (password.value === "") {
      setPasswordError("Please enter a password");
    } else if (password.value.length < 4) {
      setPasswordError("Password needs at least 5 characters");
    } else if (!PASSWORD_REGEX_1.test(password.value)) {
      setPasswordError("Please enter a valid password");
    } else if (exist === true) {
    } else {
      setStep(2);
    }
  };

  //   } else if (name.value < 2) {
  //     name.classList.add("border-red");
  //     setNameError("Please enter a valid client name");
  //   } else next();
  // };

  useEffect(() => {
    const timeoutMessage = setTimeout(() => {
      setEmailError("");
    }, 9000);
    return () => {
      clearTimeout(timeoutMessage);
    };
  }, [emailError]);
  useEffect(() => {
    const btn = document.getElementById("next-button");
    if (
      nameInput &&
      nameInput.length > 2 &&
      email &&
      email.length > 4 &&
      password &&
      password.length > 4 &&
      exist === false
    ) {
      const btn = document.getElementById("next-button");
      btn.classList.add("btn-next-active");
    } else {
      btn.classList.remove("btn-next-active");
    }
  }, [nameInput, email, password, exist]);

  const generateClientPassword = (e) => {
    e.preventDefault();
    const password = generatePassword();
    setPassword(password);
    setUserData({ ...userData, password: password });
  };

  const checkEmail = async () => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/user/check-email`, {
        email: email,
      })
      .then((res) => setExist(false))
      .catch((err) => {
        console.log("err :>> ", err);
        setEmailError("Email already used");
        setExist(true);
        toast.error("Email already used", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      });
  };

  const handleRemoveContact = (index) => {
    const values = [...contactList];
    if (contactList.length >= 2) {
      values.splice(index, 1);
      setContactList(values);
    } else {
    }
    console.log(contactList);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    console.log("name :>> ", name);
    console.log("value :>> ", value);
    const list = [...contactList];
    list[index][name] = value;
    setContactList(list);
    setUserData({ ...userData, contactsList: contactList });
  };

  return (
    <section className="first-step">
      <span className="one">1 -</span>
      <h1 className="heading01--Medium">
        Define your client's <br />
        basic information
      </h1>
      <article className="first-column">
        <form>
          <div className="input-name">
            <label htmlFor="name">Name</label>
            <input
              className="input-first-step"
              id="name"
              type="name"
              name="name"
              placeholder="Houston"
              value={userData["name"] || ""}
              required
              onChange={(e) => {
                setNameInput(e.target.value);
                setUserData({ ...userData, name: e.target.value });
              }}
            />
            <p className="error-msg">{nameError}</p>
          </div>
          <div className="input-email">
            <label>Email</label>
            <input
              className="input-first-step"
              id="email"
              type="email"
              name="email"
              placeholder="loremipsum@gmail.com"
              value={userData["email"] || ""}
              required
              onChange={(e) => {
                setEmail(e.target.value);
                setUserData({ ...userData, email: e.target.value });
              }}
              onBlur={() => checkEmail()}
            />
            <span className="error-msg">{emailError}</span>
          </div>
          <div className="input-email">
            <div className="title-password-tooltip">
              <label>Password</label>
              <div data-tip="React-tooltip">
                <BsQuestionCircle
                  className="icon-question"
                  style={{ color: "B2B2B2" }}
                />
              </div>
            </div>
            <ReactTooltip type="light">
              <span>
                Create a password with at least 5 characters,
                <br /> Uppercase lowercase & numbers accepted.
                <br />
                Don't forget it! :)
              </span>
            </ReactTooltip>
            <div className="password-input-button">
              <input
                className="input-first-step"
                id="password"
                type="text"
                name="password"
                placeholder="password"
                value={userData["password"] || ""}
                required
                onChange={(e) => {
                  setPassword(e.target.value);
                  setUserData({ ...userData, password: e.target.value });
                }}
              />
              <span className="error-msg">{passwordError}</span>
              <button
                className="generate-password-button"
                onClick={(e) => generateClientPassword(e)}
              >
                Generate
              </button>
            </div>
          </div>
        </form>
      </article>
      <section className="section-add-contact">
        <h2 className="title-add-contact">Add more contacts</h2>
        {contactList.map((el, index) => {
          const { contact, role } = el;
          return (
            <div className="iframe-style" key={index}>
              <div className="input-email">
                <label>Email</label>
                <input
                  className="input-first-step"
                  name="contact"
                  value={contact}
                  type="email"
                  placeholder="Contact email"
                  onChange={(e) => handleChange(e, index)}
                />
              </div>
              <div className="input-email">
                <label className="label-link">Rôle</label>
                <input
                  className="input-first-step"
                  name="role"
                  value={role}
                  type="text"
                  placeholder="Role"
                  onChange={(e) => handleChange(e, index)}
                />
              </div>
              {index === 0 ? (
                ""
              ) : (
                <button className="btn-delete" onClick={handleRemoveContact}>
                  <img src={crossmark} alt={crossmark} />
                </button>
              )}
            </div>
          );
        })}
        <div className="btn-div">
          <button
            className="btn-add-more"
            style={{ margin: "15px" }}
            onClick={handleAddContact}
          >
            + Add contact
          </button>
        </div>
      </section>
      <button className="btn-next" id="next-button" onClick={() => next()}>
        Next
      </button>
    </section>
  );
};

export default FirstStep;
