import React from "react";

const Support = () => {
  return (
    <div>
      <iframe
        className="iframe-notion"
        id="notion"
        title="notion"
        frameBorder="0"
        src="https://react-notion-x-54t2yilb5-m-bennai.vercel.app/c75aabac9c3c492c9ccbb889d7d64a02"
      ></iframe>
    </div>
  );
};

export default Support;
