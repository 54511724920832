import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../components/Loading";
import astro from "../assets/astro.jpg";
import { useEffect } from "react";
import axios from "axios";
import { useContext } from "react";
import { ClientInfosContext } from "../context/ClientInfosContext";
import { VscAdd } from "react-icons/vsc";
import { Loader } from "@mantine/core";

const ConsultantClientPage = () => {
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const history = useHistory();
  const { setImage, setName, setEmail, setConsultantId } =
    useContext(ClientInfosContext);
  const user = localStorage.getItem("user");
  useEffect(() => {
    console.log("user :>> ", user);
    const userParsed = JSON.parse(user);
    if (user) {
      console.log("user :>> ", user);
      const getConsultantClient = () => {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/entity/entityByConsultant/${userParsed.id}`
          )
          .then((res) => {
            setClients(res.data.entity);
            setLoading(false);
          })
          .catch((error) => {
            console.log("error :>> ", error);
            setLoading(true);
          });
      };
      getConsultantClient();
      setName(userParsed.name);
      setImage(userParsed.image);
      setEmail(userParsed.email);
      setConsultantId(userParsed.id);
    }

    console.log("user :>> ", userParsed);
  }, [user]);

  return (
    <div className="container">
      {loading ? (
        <Loader color="violet" size="xl" variant="dots" />
      ) : clients.length === 0 ? (
        <div className="no-client-div">
          <h1 className="h1-noclient">No client for now</h1>
        </div>
      ) : (
        <>
          <div
            className="add-client-div"
            onClick={() => history.push("/create-client")}
          >
            <VscAdd size={55} />
            <span>Add new</span>
          </div>
          {clients &&
            clients.map((client) => {
              const { id, name, image } = client;
              console.log(image, "image");
              console.log("oww l'image la", image);
              console.log("on veut le client", client);
              return (
                <div
                  key={id}
                  className="client-div"
                  onClick={() => history.push(`/accueil/client-file/${id}`)}
                >
                  <img src={image ? image : astro} alt={name} />
                  <h4>{client.name}</h4>
                </div>
              );
            })}
        </>
      )}
    </div>
  );
};

export default ConsultantClientPage;
