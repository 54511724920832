import pdf from "../assets/Icones/pdf.svg";
import png from "../assets/Icones/png.svg";
import doc from "../assets/Icones/doc.svg";
import ppt from "../assets/Icones/ppt.svg";
import txt from "../assets/Icones/txt.svg";
import xls from "../assets/Icones/xls.svg";
import zip from "../assets/Icones/zip.svg";
import jpg from "../assets/Icones/jpg.svg";
import link from "../assets/Icones/link.svg";

export const generatePassword = () => {
  var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  var string_length = 8;
  var randomstring = "";
  for (var i = 0; i < string_length; i++) {
    var rnum = Math.floor(Math.random() * chars.length);
    randomstring += chars.substring(rnum, rnum + 1);
  }
  return randomstring;
};

/* export const convertDate = (dateString) => {
  var date = new Date(dateString);
  return (
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
  );
}; */

export const formatBytes = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const displayFileExtensionImage = (file) => {
  const { file_mimetype } = file;
  const getFileExtension = file_mimetype.slice(-3);
  switch (getFileExtension) {
    case "pdf":
      return <img src={pdf} alt={file.name} />;
    case "png":
      return <img src={png} alt={file.name} />;
    case "doc":
      return <img src={doc} alt={file.name} />;
    case "ppt":
      return <img src={ppt} alt={file.name} />;
    case "ptx":
      return <img src={ppt} alt={file.name} />;
    case "txt":
      return <img src={txt} alt={file.name} />;
    case "xls":
      return <img src={xls} alt={file.name} />;
    case "zip":
      return <img src={zip} alt={file.name} />;
    case "jpg":
      return <img src={jpg} alt={file.name} />;
    case "peg":
      return <img src={jpg} alt={file.name} />;
    case "ocx":
      return <img src={doc} alt={file.name} />;
    case "ent":
      return <img src={doc} alt={file.name} />;
    case "lsx":
      return <img src={xls} alt={file.name} />;
    case "eet":
      return <img src={xls} alt={file.name} />;
    case "ion":
      return <img src={ppt} alt={file.name} />;
    case "ink":
      return <img src={link} alt={file.name} />;
    default:
      break;
  }
};
export const displayFileExtensionImageByValue = (value) => {
  const getFileExtension = value.slice(-3);
  switch (getFileExtension) {
    case "pdf":
      return <img src={pdf} alt={value} />;
    case "png":
      return <img src={png} alt={value} />;
    case "doc":
      return <img src={doc} alt={value} />;
    case "ppt":
      return <img src={ppt} alt={value} />;
    case "ptx":
      return <img src={ppt} alt={value} />;
    case "txt":
      return <img src={txt} alt={value} />;
    case "xls":
      return <img src={xls} alt={value} />;
    case "zip":
      return <img src={zip} alt={value} />;
    case "jpg":
      return <img src={jpg} alt={value} />;
    case "peg":
      return <img src={jpg} alt={value} />;
    case "ocx":
      return <img src={doc} alt={value} />;
    case "ent":
      return <img src={doc} alt={value} />;
    case "lsx":
      return <img src={xls} alt={value} />;
    case "eet":
      return <img src={xls} alt={value} />;
    case "ion":
      return <img src={ppt} alt={value} />;
    default:
      break;
  }
};
