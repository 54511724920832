import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import astro from "../assets/astro.jpg";
import uploadIcon from "../assets/upload-basic-infos.svg";
import circleplus from "../assets/circle-plus.svg";
import crossmark from "../assets/cross-mark.svg";
import { RiDeleteBinLine } from "react-icons/ri";
import Select from "react-select";
import { userJob } from "../components/data";
import { consultantRole } from "../components/data";

toast.configure();
const ManageConsultant = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [file, setFile] = useState("");
  const [password, setPassword] = useState("");
  const [job, setJob] = useState("");
  const [consultants, setConsultants] = useState();
  const [memberRole] = useState(consultantRole);
  //const [consultantData, setConsultantData] = useState();
  const [client, setClient] = useState([
    {
      /*  name: "",
      role: "",
      id: "", */
    },
  ]);
  const [clientFromApi, setClientFromAPI] = useState();

  const customStyles = {
    option: (provided, state) => ({
      ...provided,

      borderBottom: "1px dotted #F6F6F6",
      color: state.isSelected ? "#6935d7" : "black",
      padding: 20,
      background: "white",
      borderRadius: "0.625rem",
      "&:hover": {
        background: "#F6F6F6",
      },
    }),

    indicatorSeparator: () => ({
      border: "none",
    }),
    indicatorsContainer: () => ({
      borderLeft: "1px solid #f1f1f1",
      padding: "10px",
      marginRight: "2px",
      cursor: "pointer",
    }),

    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      width: 312,
      height: 57,
      backgroundColor: "white",
      border: "0.5px solid #ececee",
      borderRadius: "0.225rem",
      alignItems: "center",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  useEffect(() => {
    const getConsultants = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/user/allConsultants`)
        .then((res) => {
          setConsultants(res.data);
        })
        .catch((err) => {
          console.log("err :>> ", err);
          toast.error("Cannot fetch consulant", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
        });
    };
    getConsultants();
  }, []);

  useEffect(() => {
    const getAllClient = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/entity/all`)
        .then((res) => {
          setClientFromAPI(res.data.allEntity);
        })
        .catch((err) => console.log("err :>> ", err));
    };

    getAllClient();
  }, []);

  const handleAddClients = () => {
    setClient([...client, { name: "", id: "" }]);
  };
  const handleRemoveClients = (index) => {
    const values = [...client];
    values.splice(index, 1);
    setClient(values);
  };
  const handleJob = (obj, index) => {
    const { name, value } = obj;
    setJob(value);
    /*     const list = [...member];
    list[index][name] = value;
    setMember(list);
    console.log("member :>> ", member);
    setConsultantData({ ...consultantData, member: member }); */
    //list[index][obj.name] = value;
  };
  console.log("client :>> ", client.length);
  const handleConsultant = (e) => {
    console.log("client :>> ", client);
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("role", "consultant");
    formData.append("image", file);
    formData.append("job", job);

    formData.append(
      "clients",
      client && client[0]?.name?.length !== 0 ? JSON.stringify(client) : ""
    );

    console.log("formData :>> ", formData);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/user/createConsulant`,
        formData
      )
      .then((res) => {
        console.log("res.data :>> ", res.data);
        toast.success("consultant add success", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setTimeout(() => {
          const getFiles = () => {
            axios
              .get(`${process.env.REACT_APP_API_URL}/api/user/allConsultants`, {
                headers: {
                  accessToken: localStorage.getItem("accessToken"),
                },
              })
              .then((res) => {
                console.log("res from getImage from file upload :>> ", res);
                setConsultants(res.data);
                console.log("res.data du timeout:>> ", res.data);
              })
              .catch((err) => {
                console.log("err :>> ", err);
              });
          };
          getFiles();
        }, 2000);
      })
      .catch((error) => {
        console.log("error :>> ", error);
        toast.error("an error was occured ici", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      });
  };

  const handleClient = (obj, index) => {
    const { name, value, id } = obj;
    const list = [...client];
    list[index][name] = value;
    console.log('list[index]["id"] = id; :>> ', (list[index]["id"] = id));
    setClient(list);
  };
  const handleRole = (obj, index) => {
    const { name, value } = obj;
    const list = [...client];
    list[index][name] = value;
    setClient(list);
    //console.log("member :>> ", client);
    // setConsultantData({ ...consultantData, client: client });
    //list[index][obj.name] = value;
  };
  //console.log("consultantData :>> ", consultantData);

  const handleRemoveConsultant = async (id, imageName) => {
    await axios
      .delete(`${process.env.REACT_APP_API_URL}/api/user/delete/${id}`, {
        data: { imageName: imageName },
      })
      .then((res) => {
        toast.success("Consultant delete  success", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setTimeout(() => {
          const getFiles = () => {
            axios
              .get(`${process.env.REACT_APP_API_URL}/api/user/allConsultants`, {
                headers: {
                  accessToken: localStorage.getItem("accessToken"),
                },
              })
              .then((res) => {
                console.log("res from getImage from file upload :>> ", res);
                setConsultants(res.data);
                console.log("res.data du timeout:>> ", res.data);
              })
              .catch((err) => {
                console.log("err :>> ", err);
              });
          };
          getFiles();
        }, 1000);
      })
      .catch((err) => {
        console.log("err :>> ", err);
        toast.error("Cannot delete consultant", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      });
  };
  return (
    <section className="manage-consultants">
      <div className="content-manage-consultant">
        <h1 className="title-manage-consultant">Manage consultants</h1>
        <div>
          <h3 className="title-change-info-settings">
            Add new consultant in database
          </h3>
        </div>

        <form onSubmit={handleConsultant}>
          <section className="section-basic-info-settings">
            {/* <div className="display-name-email-settings">
          <span>{name}</span>
          <span className="email-display">{email}</span>
        </div> */}

            <div className="input-name-email-settings">
              {/* <form className="form-input-name-email" onSubmit={handleSubmitName}></form> */}
              <div className="input-name-settings">
                <div className="input-name-label">
                  <label htmlFor="name">Name</label>

                  <input
                    className="input-first-step"
                    id="name"
                    type="name"
                    name="name"
                    required
                    placeholder="Consultant Name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              {/* <div className="btn-align-right">
              <button className="btn-client-settings-update">
                Update Name
              </button>
            </div> */}
              {/* <form className="form-input-name-email" onSubmit={handleSubmitEmail}> */}
              <div className="input-email-settings">
                <div className="input-label-email">
                  <label>Email</label>

                  <input
                    className="input-first-step"
                    id="email"
                    type="email"
                    name="email"
                    required
                    placeholder="Consultant Email "
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              {/* <div className="btn-align-right">
              <button className="btn-client-settings-update">
                Update Email
              </button>
            </div> */}
            </div>
            {/* <form className="form-input-name-email" onSubmit={handleSubmitEmail}> */}
            <div className="input-password-job-settings">
              <div className="password-label-input">
                <label>Password</label>

                <input
                  className="input-first-step"
                  id="password"
                  type="password"
                  name="password"
                  placeholder="**********"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="job-label-input">
                <label className="label-job">Job</label>
                <Select
                  options={userJob}
                  styles={customStyles}
                  placeholder="Select Job"
                  onChange={(obj) => handleJob(obj)}
                  name="job"
                />
              </div>
            </div>

            {/* <div className="btn-align-right">
              <button className="btn-client-settings-update">
                Update Email
              </button>
            </div> */}
          </section>
          <div>
            <h1>Add to a client</h1>
            {client.map((el, index) => {
              const option =
                clientFromApi &&
                clientFromApi.map((el) => {
                  return {
                    value: el.name,
                    label: el.name,
                    name: "name",
                    id: el.id,
                  };
                });

              return (
                <div className="clients-label-input" key={index}>
                  <Select
                    options={option}
                    styles={customStyles}
                    placeholder="Client name"
                    name="name"
                    onChange={(obj) => handleClient(obj, index)}
                  />
                  <div className="select-role-div">
                    <Select
                      options={memberRole}
                      styles={customStyles}
                      placeholder="Consultant role"
                      onChange={(obj) => handleRole(obj, index)}
                      name="role"
                    />
                  </div>
                  {index === 0 ? (
                    ""
                  ) : (
                    <span
                      className="cross-delete-clients"
                      onClick={handleRemoveClients}
                    >
                      <img src={crossmark} alt={crossmark} />
                    </span>
                  )}
                </div>
              );
            })}
            <div className="div-btn-add-clients">
              <span className="btn-add-clients" onClick={handleAddClients}>
                <img src={circleplus} alt={circleplus} />
              </span>
            </div>
          </div>
          <div className="client-logo-container">
            {/* <img className="client-logo" src={image ? image : astro} alt="logo" /> */}
            <img className="client-logo" src={astro} alt="logo" />
            <div className="button-description-container">
              <div className="input-settings-div">
                <div className="upload-logo-and-input">
                  <img src={uploadIcon} alt={uploadIcon} />
                  <input
                    id="choose-file"
                    className="custom-file-input"
                    type="file"
                    name="image"
                    accept="image/*"
                    required
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}

                    //className="btn-client-settings-upload"
                  />
                </div>

                <div className="button-update-settings">
                  <h6 className="description-logo-settings">
                    Max File Size: 1MB
                    <br />
                    200x200 px, PNG/JPG format
                  </h6>
                  <button className="btn-upload" type="submit">
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className="consultant-list-div">
          <h3 className="title-change-info-settings">
            List of all consultants
          </h3>
          <div className="table-title">
            <div className="photo-consultant"></div>
            <div className="name-consultant">Name</div>
            <div className="job-consultant">Job</div>
            <div className="email-consultant">Email</div>
          </div>
          {consultants &&
            consultants.map((consultant) => {
              const { name, job, id, image, email } = consultant;

              const imageNameToDelete = image.split("/")[4];

              return (
                <div className="consultant-display" key={id}>
                  <div className="consultant-photo-info">
                    <img
                      className="consultant-img"
                      src={image ? image : astro}
                      alt={name}
                    />
                  </div>

                  <h4 className="consultant-name-info">{name}</h4>
                  <h4 className="consultant-job-info">{job}</h4>
                  <h4 className="consultant-email-info">{email}</h4>
                  <div className="delete-consultant-div">
                    <RiDeleteBinLine
                      size={20}
                      className="delete-btn"
                      onClick={() =>
                        handleRemoveConsultant(id, imageNameToDelete)
                      }
                    ></RiDeleteBinLine>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default ManageConsultant;
