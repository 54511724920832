import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { ReactComponent as Homesvg } from "../../../assets/home-side.svg";
import { ReactComponent as Astrosvg } from "../../../assets/astro-side.svg";
import { ReactComponent as Supportsvg } from "../../../assets/support-side.svg";
import { ReactComponent as Settingssvg } from "../../../assets/settings-side.svg";

const ConsulantLink = ({ arrow }) => {
  let { url } = useRouteMatch();
  return (
    <div>
      <h1>MENU</h1>
      <NavLink className="link-menu-sidebar" to={`${url}/home`}>
        <Homesvg />
        <span className={arrow ? "" : "display-none"}>Home</span>
      </NavLink>
      <NavLink className="link-menu-sidebar" to={`${url}/YNcompany`}>
        <Astrosvg />
        <span className={arrow ? "" : "display-none"}>Y&N Company</span>
      </NavLink>
      {/* <NavLink to={`${url}/news`}>
        <RiFolderUnknowLine size={20} />
        <span className={arrow ? "" : "display-none"}>News</span>
      </NavLink> */}
      {/* <NavLink to={`${url}/formation`}>
        <IoSchoolOutline size={20} />
        <span className={arrow ? "" : "display-none"}>Formation</span>
      </NavLink> */}
      <NavLink className="link-menu-sidebar" to={`${url}/support`}>
        <Supportsvg />
        <span className={arrow ? "" : "display-none"}>Support</span>
      </NavLink>
      <NavLink className="link-menu-sidebar" to={`${url}/mysettings`}>
        <Settingssvg />
        <span className={arrow ? "" : "display-none"}>My Settings</span>
      </NavLink>
    </div>
  );
};

export default ConsulantLink;
