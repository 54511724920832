import axios from "axios";
import React, { useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { toast } from "react-toastify";

const Dashboards = ({ links, setLinks, id, roadmapLink, setRoadmapLink }) => {
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [deleteRoadmapStatus, setDeleteRoadmapStatus] = useState(false);
  const [linkDashboard, setLinkDashboard] = useState("");
  const [titleDashboard, setTitleDashboard] = useState("");
  const [titleRoadmap, setTitleRoadmap] = useState("");
  const [linkRoadmap, setLinkRoadmap] = useState("");

  const deleteLink = async (id) => {
    await axios
      .delete(`${process.env.REACT_APP_API_URL}/api/datastudio/delete/${id}`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        toast.success("success", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setDeleteStatus(true);
      })
      .catch((err) => {
        console.log("err :>> ", err);
        toast.error("an error was occured", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      });
  };

  const deleteRoadmapLink = async (id) => {
    await axios
      .delete(`${process.env.REACT_APP_API_URL}/api/roadmap/delete/${id}`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        toast.success("success", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setDeleteRoadmapStatus(true);
      })
      .catch((err) => {
        console.log("err :>> ", err);
        toast.error("an error was occured", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      });
  };

  if (deleteStatus) {
    const snapshopLinkOnDelete = () => {
      setTimeout(() => {
        const getLinks = () => {
          axios
            .get(`${process.env.REACT_APP_API_URL}/api/entity/${id}`, {
              headers: {
                accessToken: localStorage.getItem("accessToken"),
              },
            })
            .then((res) => {
              setLinks(res.data.entity.datastudio);
              setDeleteStatus(false);
            })
            .catch((err) => {
              console.log("err :>> ", err);
            });
        };
        getLinks();
      }, 300);
    };
    snapshopLinkOnDelete();
    setDeleteStatus(false);
  }

  if (deleteRoadmapStatus) {
    const snapshopLinkOnDelete = () => {
      setTimeout(() => {
        const getRoadmapsLinks = () => {
          axios
            .get(`${process.env.REACT_APP_API_URL}/api/entity/${id}`, {
              headers: {
                accessToken: localStorage.getItem("accessToken"),
              },
            })
            .then((res) => {
              setRoadmapLink(res.data.entity.roadmap);

              setDeleteRoadmapStatus(false);
            })
            .catch((err) => {
              console.log("err :>> ", err);
            });
        };
        getRoadmapsLinks();
      }, 300);
    };
    snapshopLinkOnDelete();
    setDeleteRoadmapStatus(false);
  }

  const handleSubmitLink = (e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/datastudio/create`,
        {
          link: linkDashboard,
          title: titleDashboard,
          entityId: id,
        },
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      )
      .then((res) => {
        console.log("res.data :>> ", res.data);
        toast.success(res.data.msg, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setTimeout(() => {
          const getLinks = () => {
            axios
              .get(`${process.env.REACT_APP_API_URL}/api/entity/${id}`, {
                headers: {
                  accessToken: localStorage.getItem("accessToken"),
                },
              })
              .then((res) => {
                console.log("lemme see this data", res.data.entity.datastudio);
                setLinks(res.data.entity.datastudio);
              })
              .catch((err) => {
                console.log("err :>> ", err);
              });
          };
          getLinks();
        }, 300);
      })

      .catch((err) => {
        console.log("err :>> ", err);
        toast.error("an error was occured", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      });
  };

  const handleSubmitRoadmap = (e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/roadmap/create`,
        {
          roadmapLink: linkRoadmap,
          title: titleRoadmap,
          entityId: id,
        },
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      )
      .then((res) => {
        console.log("res.data :>> ", res.data);
        toast.success(res.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setTimeout(() => {
          const getLinks = () => {
            axios
              .get(`${process.env.REACT_APP_API_URL}/api/entity/${id}`, {
                headers: {
                  accessToken: localStorage.getItem("accessToken"),
                },
              })
              .then((res) => {
                console.log(
                  "lets see whats inside:>> ",
                  res.data.entity.roadmap
                );
                console.log("roadmaaaaaaapLinks :>> ", setRoadmapLink);
                setRoadmapLink(res.data.entity.roadmap);
              })
              .catch((err) => {
                console.log("err :>> ", err);
              });
          };
          getLinks();
        }, 300);
      })
      .catch((err) => {
        console.log("err :>> ", err);
        toast.error("an error was occured", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      });
  };

  console.log("links :>> ", links);
  console.log("roadmapsLinks :>> ", roadmapLink);
  return (
    <section className="dashboards-manage-client">
      <div className="dashboard-settings">
        <h2 className="title-dashboards-mc">Client's Dashboards</h2>
        <div className="datastudio-links">
          {links &&
            links.map((liens) => {
              const { link, id, title } = liens;
              return (
                <div className="container-datastudio-links" key={id}>
                  <div>
                    <h3 className="title-datastudios">{title}</h3>
                  </div>
                  <div className="display-datastudio-links">
                    <div className="datastudio-links">
                      <a href={link} target="_blank" rel="noreferrer">
                        {link}
                      </a>
                    </div>
                    <RiDeleteBinLine
                      size={20}
                      className="delete-link-btn"
                      onClick={() => deleteLink(id)}
                    ></RiDeleteBinLine>
                  </div>
                </div>
              );
            })}
          <h2 className="title-add-datastudio-mc">Add Data Studio</h2>
        </div>
      </div>
      <div className="input-label-dashboard-title">
        <label className="label-datastudio-link-mc">Title</label>
        <form action="" className="form-input-link">
          <input
            className="input-add-dashboard-title"
            name="title"
            value={titleDashboard}
            type="text"
            placeholder="Datastudio title"
            onChange={(e) => setTitleDashboard(e.target.value)}
          />

          <h2 className="label-datastudio-link-mc">Iframe Data Studio Link</h2>

          <div className="iframe-style-settings">
            <input
              className="input-fourth-step"
              name="link"
              value={linkDashboard}
              type="text"
              placeholder="Paste your Link"
              required
              onChange={(e) => setLinkDashboard(e.target.value)}
            />
            {/* <img
              src={confirmIcon}
              alt={confirmIcon}
              onClick={handleSubmitLink}
              type="submit"
            /> */}
            <button
              className="btn-dashboard-page"
              onClick={handleSubmitLink}
              type="submit"
            >
              Add
            </button>
          </div>
          {/* <div className="btn-align-right">
            <button
              onClick={handleSubmitLink}
              type="submit"
              className="btn-client-settings-update download-btn"
            >
              Add Link
            </button>
          </div> */}
        </form>
      </div>
      <div className="roadmap-settings">
        <h2 className="title-roadmap-mc">Client's Roadmap</h2>

        <div className="datastudio-links">
          {roadmapLink &&
            roadmapLink.map((liens) => {
              const { roadmapLink, id, title } = liens;
              return (
                <div className="container-datastudio-links" key={id}>
                  <div>
                    <h3 className="title-datastudios">{title}</h3>
                  </div>
                  <div className="display-datastudio-links">
                    <div className="datastudio-links">
                      <a href={roadmapLink} target="_blank" rel="noreferrer">
                        {roadmapLink}
                      </a>
                    </div>
                    <RiDeleteBinLine
                      size={20}
                      className="delete-link-btn"
                      onClick={() => deleteRoadmapLink(id)}
                    ></RiDeleteBinLine>
                  </div>
                </div>
              );
            })}
          {/* <a
                href={roadmapLink && roadmapLink.roadmapLink}
                target="_blank"
                rel="noreferrer"
              >
                {roadmapLink && roadmapLink.roadmapLink}
              </a> */}
        </div>

        <h1 className="title-add-datastudio-mc">Add Roadmap</h1>

        <form
          action=""
          className="form-input-link"
          // onSubmit={handleSubmitRoadmap}
        >
          <div className="iframe-style-settings">
            <section className="section-roadmap-links">
              <div className="div-title-input">
                <label className="label-datastudio-link-mc">Title</label>
                <input
                  className="input-fourth-step"
                  name="title"
                  value={titleRoadmap}
                  type="text"
                  placeholder="Paste your Link"
                  onChange={(e) => setTitleRoadmap(e.target.value)}
                />
              </div>

              <h2 className="label-datastudio-link-mc">Iframe Roadmap Link</h2>
              <div className="div-roadmap-button">
                <input
                  className="input-fourth-step"
                  name="roadmapLink"
                  value={linkRoadmap}
                  type="text"
                  placeholder="Paste your Link"
                  required
                  onChange={(e) => setLinkRoadmap(e.target.value)}
                />

                <button
                  className="btn-dashboard-page"
                  onClick={handleSubmitRoadmap}
                  type="submit"
                >
                  Add
                </button>
              </div>
            </section>
            {/* <img src={updateIcon} alt={updateIcon} type="submit" /> */}
          </div>

          {/* <div className="btn-align-right">
            <button
              type="submit"
              className="btn-client-settings-update download-btn"
            >
              Update Link
            </button>
          </div> */}
        </form>
      </div>
    </section>
  );
};

export default Dashboards;
