import React from "react";

const KnowledgeBase = () => {
  return (
    <section className="section-knowledge-base">
      <iframe
        className="iframe-notion"
        id="notion"
        title="notion"
        frameBorder="0"
        src="https://react-notion-x-54t2yilb5-m-bennai.vercel.app/feda5990dfed4d2fb940047e605f5aa2"
      ></iframe>
    </section>
  );
};

export default KnowledgeBase;
