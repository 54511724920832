import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { multiStepContext } from "../../context/stepContext";
import { BsQuestionCircle } from "react-icons/bs";
import ReactTooltip from "react-tooltip";
import crossmark from "../../assets/cross-mark.svg";

const FourthStep = () => {
  const { setStep, userData, finalData, setUserData } =
    useContext(multiStepContext);

  const [linkList, setLinksList] = useState([
    {
      link: "",
      title: "",
    },
  ]);

  const [roadmapList, setRoadmapList] = useState([
    {
      link: "",
      title: "",
    },
  ]);

  const [roadmapError, setRoadmapError] = useState();

  // const nextStep = () => {
  //   const roadmap = document.getElementById("roadmap");
  //   if (roadmap.value === "") {
  //     roadmap.classList.add("border-red");
  //     setRoadmapError("Please enter your roadmap link");
  //   } else if (!roadmapList.value.includes("/embed/")) {
  //     setRoadmapError("Please, verify your link, we need the the /embed/");
  //   } else if (roadmapList.value.length !== 93) {
  //     setRoadmapError("Verify your link please, its too short");
  //   } else {
  //     setStep(4);
  //   }
  // };

  // useEffect(() => {
  //   const btn = document.getElementById("next-button");
  //   if (roadmapList && roadmapList.length >= 93) {
  //     const btn = document.getElementById("next-button");
  //     btn.classList.add("btn-next-active");
  //   } else {
  //     btn.classList.remove("btn-next-active");
  //   }
  // }, [roadmapList]);

  const handleAddLink = () => {
    setLinksList([...linkList, { link: "" }]);
  };

  const handleAddRoadmap = () => {
    setRoadmapList([...roadmapList, { link: "" }]);
  };

  const handleRemoveLink = (index) => {
    const values = [...linkList];
    if (linkList.length >= 2) {
      values.splice(index, 1);
      setLinksList(values);
    } else {
    }
    console.log(linkList);
  };

  const handleRemoveRoadmap = (index) => {
    const values = [...roadmapList];
    if (roadmapList.length >= 2) {
      values.splice(index, 1);
      setRoadmapList(values);
    } else {
    }
    console.log(roadmapList);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    console.log("name :>> ", name);
    console.log("value :>> ", value);
    const list = [...linkList];
    list[index][name] = value;
    setLinksList(list);
    setUserData({ ...userData, dataStudioLinksList: linkList });
  };

  const handleChangeRoadmap = (e, index) => {
    const { name, value } = e.target;
    console.log("name :>> ", name);
    console.log("value :>> ", value);
    const list = [...roadmapList];
    list[index][name] = value;
    setRoadmapList(list);
    setUserData({ ...userData, roadmapLinksList: roadmapList });
  };

  return (
    <div className="fourth-step">
      <p className="four">3 -</p>
      <h1 className="title-step4">
        Add the client's
        <br />
        dashboard
      </h1>
      <div className="input-datastudio">
        {/* ROADMAP INTEGRATION PART */}
        <div className="title-questionmark2">
          <h1 className="datastudio-link-title">Roadmap Link</h1>
          <div data-tip="React-tooltip">
            <BsQuestionCircle
              className="icon-question"
              style={{ color: "B2B2B2" }}
            />
          </div>
          <ReactTooltip type="light">
            <span>
              To add a roadmap you have to share
              <br /> the iframe link of your datastudio
              <br /> which is in the share menu.
            </span>
          </ReactTooltip>
        </div>

        {roadmapList.map((links, index) => {
          const { link, title } = links;
          return (
            <div className="iframe-style" key={index}>
              {index === 0 ? (
                ""
              ) : (
                <button className="btn-delete" onClick={handleRemoveRoadmap}>
                  <img src={crossmark} alt={crossmark} />
                </button>
              )}
              <div className="title-questionmark">
                <label>Title</label>
                <input
                  className="input-fourth-step-title"
                  name="title"
                  value={title}
                  type="text"
                  placeholder="Roadmap title"
                  onChange={(e) => handleChangeRoadmap(e, index)}
                />
              </div>
              <label className="label-link">Link</label>
              <input
                className="input-roadmap-link"
                name="link"
                value={link}
                type="text"
                placeholder="Paste your link"
                // onChange={(e) => {
                //   setRoadmap(e.target.value);
                //   setUserData({ ...userData, roadmapLink: e.target.value });
                // }}
                onChange={(e) => handleChangeRoadmap(e, index)}
              />
              {/* <span className="error-msg-roadmap">{roadmapError}</span> */}
            </div>
          );
        })}

        <button
          className="btn-add-more"
          style={{ margin: "15px" }}
          onClick={handleAddRoadmap}
        >
          + Add new
        </button>
        {/* DATASTUDIO INTEGRATION PART  */}
        <div className="title-questionmark2">
          <h1 className="datastudio-link-title">Data Studio Link</h1>
          <div data-tip="React-tooltip">
            <BsQuestionCircle
              className="icon-question"
              style={{ color: "B2B2B2" }}
            />
          </div>
          <ReactTooltip type="light">
            <span>
              To add a dashboard you have to share
              <br /> the iframe link of your datastudio
              <br /> which is in the share menu.
            </span>
          </ReactTooltip>
        </div>
        {linkList.map((link, index) => {
          const { lien, title } = link;
          return (
            <div className="iframe-style" key={index}>
              {index === 0 ? (
                ""
              ) : (
                <button className="btn-delete" onClick={handleRemoveLink}>
                  <img src={crossmark} alt={crossmark} />
                </button>
              )}
              <label>Title</label>
              <input
                className="input-fourth-step-title"
                name="title"
                value={title}
                type="text"
                placeholder="Datastudio title"
                onChange={(e) => handleChange(e, index)}
              />
              <label className="label-link">Link</label>
              <input
                className="input-fourth-step"
                name="link"
                value={lien}
                type="text"
                placeholder="Paste your link"
                onChange={(e) => handleChange(e, index)}
              />
            </div>
          );
        })}
        <button
          className="btn-add-more"
          style={{ margin: "15px" }}
          onClick={handleAddLink}
        >
          + Add new
        </button>
      </div>
      <button className="btn-next" id="next-button" onClick={() => setStep(4)}>
        Next
      </button>
    </div>
  );
};

export default FourthStep;
