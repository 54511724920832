import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { ReactComponent as Filessvg } from "../../../assets/files-side.svg";
import { ReactComponent as Dashboardsvg } from "../../../assets/dashboard-side.svg";
import { ReactComponent as Roadmapsvg } from "../../../assets/roadmap-side.svg";
import { ReactComponent as Myteamsvg } from "../../../assets/team-side.svg";
import { ReactComponent as Settingssvg } from "../../../assets/settings-side.svg";

const ClientLink = ({ arrow, clientId }) => {
  let { url } = useRouteMatch();
  return (
    <div className="div-content-client">
      <h1>YOUR PROJECT </h1>
      <div className="div-link-client">
        <div className="links-img-sidebar">
          <NavLink
            className="link-menu-sidebar"
            to={`${url}/client-file/${clientId}`}
          >
            <Filessvg />
            <span
              style={{ marginLeft: "0.938rem" }}
              className={arrow ? "" : "display-none"}
            >
              Files
            </span>
          </NavLink>
        </div>

        <NavLink
          className="link-menu-sidebar"
          to={`${url}/client-page-roadmap/${clientId}`}
        >
          <Roadmapsvg />
          <span
            style={{ marginLeft: "0.938rem" }}
            className={arrow ? "" : "display-none"}
          >
            Roadmap
          </span>
        </NavLink>
        <NavLink
          className="link-menu-sidebar"
          to={`${url}/client-page-dashboard/${clientId}`}
        >
          <Dashboardsvg />
          <span
            style={{ marginLeft: "0.938rem" }}
            className={arrow ? "" : "display-none"}
          >
            Dashboard
          </span>
        </NavLink>
        <NavLink
          className="link-menu-sidebar"
          to={`${url}/client-page-myTeam/${clientId}`}
        >
          <Myteamsvg />
          <span
            style={{ marginLeft: "0.938rem" }}
            className={arrow ? "" : "display-none"}
          >
            My Team
          </span>
        </NavLink>
        <NavLink className="link-menu-sidebar" to={`${url}/client-setting`}>
          <Settingssvg />
          <span
            style={{ marginLeft: "0.938rem" }}
            className={arrow ? "" : "display-none"}
          >
            Settings
          </span>
        </NavLink>
      </div>
    </div>
  );
};

export default ClientLink;
