//import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
//import download from "downloadjs";
/* import { FiDownload } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri"; */
import { toast } from "react-toastify";
import Modal from "react-modal";
import { GrFormClose } from "react-icons/gr";
import { displayFileExtensionImage } from "../../utils/utils";
import DragAndDrop from "../DragAndDrop";
import axios from "axios";
import download from "downloadjs";
import { FiDownload } from "react-icons/fi";
import { HiOutlineExternalLink } from "react-icons/hi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AuthContext } from "../../auth/auth";
import { Redirect, useParams } from "react-router";
import { format } from "date-fns";
import { AiOutlineTag } from "react-icons/ai";
import Select from "react-select";
import { ClientInfosContext } from "../../context/ClientInfosContext";

import { VscFiles } from "react-icons/vsc";
import FilesFolder from "./file/FileFolder";
import FilesList from "./file/FilesList";

toast.configure();
const ClientFile = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen2, setIsOpen2] = useState(false);
  const [canIcall, setCanICall] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState("doc");
  const [fileId, setFileId] = useState("1234");
  const [fileGcpName, setFileGcpName] = useState("zerrdserr");
  const [toggleState, setToggleState] = useState(1);
  const [linkFile, setLinkFile] = useState({
    title: "",
    link: "",
  });
  const [items, setItems] = useState([]);
  const allCategories = [
    "All",
    ...new Set(files && files.map((file) => file.tags)),
  ];
  const toggleTab = (index) => {
    setToggleState(index);
  };

  function openModal() {
    setIsOpen(true);
  }
  console.log(files);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }
  const { id } = useParams();
  const entityId = id;

  const { setClientName, setClientImage, setClientId, showClientNav } =
    useContext(ClientInfosContext);

  useEffect(() => {
    const getFiles = () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/entity/${entityId}`, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          setFiles(res.data.entity.docs);
          setItems(res.data.entity.docs);
          console.log("yooo");
          /* showClientNav(true); */
          setClientId(entityId);
          if (authState.role === "admin" || authState.role === "consultant") {
            setClientName(res.data.entity.name);
            setClientImage(res.data.entity.image);
          }
        })
        .catch((err) => {
          console.log("err :>> ", err);
        });
    };
    getFiles();
  }, [entityId]);

  function closeModal() {
    const getFiles = () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/entity/${entityId}`, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          console.log("res from getImage from file upload :>> ", res);
          setFiles(res.data.entity.docs);
          console.log("res.data :>> ", res.data.entity.image);
        })
        .catch((err) => {
          console.log("err :>> ", err);
        });
    };
    setCanICall(true);
    //getFiles();
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      height: "auto",
      width: "55rem",
      padding: "0rem",
      borderRadius: "10px",
    },
  };

  let tag;

  const handle = (obj, id) => {
    console.log("obj :>> ", obj);
    console.log("id :>> ", id);
    const { value } = obj;
    tag = value;
    console.log("tag :>>  dans handle", tag);
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/docs/update/${id}`,
        { tags: value },
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      )
      .then((res) => {
        console.log("res >> ", res.data);
        toast.success(res.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setTimeout(() => {
          const getFiles = () => {
            axios
              .get(`${process.env.REACT_APP_API_URL}/api/entity/${entityId}`, {
                headers: {
                  accessToken: localStorage.getItem("accessToken"),
                },
              })
              .then((res) => {
                console.log("res from getImage from file upload :>> ", res);
                setFiles(res.data.entity.docs);
                console.log("res.data :>> ", res.data.entity.image);
              })
              .catch((err) => {
                console.log("err :>> ", err);
              });
          };
          getFiles();
        }, 400);
      })
      .catch((err) => {
        console.log("err :>> ", err);
        toast.error("an error was occured", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      });
    //list[index][obj.name] = value;
  };

  const data = [
    {
      value: "SEO",
      label: "SEO",
      name: "name",
      //icon: <AiOutlineTag />,
    },
    {
      value: "SEA",
      label: "SEA",
      name: "name",
      //icon: <AiOutlineTag />,
    },
    {
      value: "Content",
      label: "Content",
      name: "name",
      // icon: <AiOutlineTag />,
    },
    {
      value: "Analytics",
      label: "Analytics",
      name: "name",
      //icon: <AiOutlineTag />,
    },
    {
      value: "CRO",
      label: "CRO",
      name: "name",
      //icon: <AiOutlineTag />,
    },
    {
      value: "Multi channel",
      label: "Multi channel",
      name: "name",
      //icon: <AiOutlineTag />,
    },
    {
      value: "Administratif",
      label: "Administratif",
      name: "name",
      //icon: <AiOutlineTag />,
    },
  ];

  if (canIcall) {
    console.log("canIcall :>> ", canIcall);

    /*    const getFiles = () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/entity/${entityId}`, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          console.log("res from getImage from file upload :>> ", res);
          setFiles(res.data.entity.docs);
          setCanICall(false);
        })
        .catch((err) => {
          console.log("err :>> ", err);
        });
    };
    getFiles();
    setCanICall(false); */
    setTimeout(() => {
      console.log("====================================");
      console.log("dans let timeout");
      console.log("====================================");
      const getFiles = () => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/api/entity/${entityId}`, {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          })
          .then((res) => {
            console.log("res from getImage from file upload :>> ", res);
            setFiles(res.data.entity.docs);
            setCanICall(false);
            console.log("res.data :>> ", res.data.entity.image);
          })
          .catch((err) => {
            console.log("err :>> ", err);
          });
      };
      getFiles();
    }, 400);
  }
  // const customSingleValue = ({ data }) => (
  //   <div className="input-select">
  //     <div className="input-select__single-value">
  //       {data.icon && <span className="input-select__icon">{data.icon}</span>}
  //       <span>{data.label}</span>
  //     </div>
  //   </div>
  // );

  const customStyles2 = {
    option: (provided, state) => ({
      ...provided,

      borderBottom: "1px dotted #F6F6F6",
      color: state.isSelected ? "#6935d7" : "black",
      // padding: 20,
      background: "white",
      display: "flex",

      borderRadius: "0.625rem",
      "&:hover": {
        background: "#F6F6F6",
      },
    }),

    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999, width: 86 }),

    indicatorSeparator: () => ({
      border: "none",
    }),

    indicatorsContainer: () => ({
      display: "none",
    }),

    placeholder: () => ({
      height: "0rem",
    }),

    valueContainer: () => ({
      width: 0,
    }),

    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: 0,
      marginRight: 22,
      color: "black",
      display: "flex",
      fontSize: 10,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "0.625rem",
      backgroundColor: "none",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };
  /*   const [files, setFiles] = useState([]);

  useEffect(() => {
    const getFiles = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/docs/files/${entityId}`, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          console.log("res :>> ", res.data.files);
          setFiles(res.data.files);
        })
        .catch((err) => {console.log("err :>> ", err)});
    };
    getFiles();
    return () => {
      setFiles();
    };
  }, [entityId]); */

  const { authState } = useContext(AuthContext);
  const removeFile = async (id, gcpName) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/docs/delete/${id}`,

        {
          data: { name: gcpName },
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      )
      .then((res) => {
        console.log("res.data :>> ", res.data);
        toast.success(res.data.msg, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        setTimeout(() => {
          const getFiles = () => {
            axios
              .get(`${process.env.REACT_APP_API_URL}/api/entity/${entityId}`, {
                headers: {
                  accessToken: localStorage.getItem("accessToken"),
                },
              })
              .then((res) => {
                console.log("res from getImage from file upload :>> ", res);
                setFiles(res.data.entity.docs);
              })
              .catch((err) => {
                console.log("err :>> ", err);
              });
          };
          getFiles();
        }, 400);
      })
      .catch((err) => {
        console.log("err :>> ", err);
        console.log("err.response :>> ", err.response);
        toast.error("an error was occured", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      });
  };

  const downLoadFileGcp = async (gcpName, title, file_mimetype) => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/docs/downloadName/${gcpName}`,
          {
            responseType: "blob",
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          }
        )
        .then((res) => {
          console.log("res :>> ", res);
          toast.success("your file will be donwloaded soon", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
          return download(res.data, title, file_mimetype);
        })
        .catch((err) => {
          console.log("err :>> ", err);
          toast.error("an error was occured", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
        });
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.log("Error while downloading file. Try again later");
      }
    }
  };
  const filterFile = (category) => {
    if (category === "All") {
      setItems(files);
      return;
    }
    const newItems = files.filter((item) => item.tags === category);
    setItems(newItems);
  };

  if (!authState.status) {
    return <Redirect to="/" />;
  }
  function openModal2() {
    setIsOpen2(true);
  }
  function afterOpenModal2() {
    // references are now sync'd and can be accessed.
  }

  function closeModal2() {
    setFileGcpName();
    setFileId();
    setFileName();
    setIsOpen2(false);
    setLinkFile("");
  }
  const customStylesModal2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "30rem",
      height: "12rem",
      padding: "0px",
    },
  };

  const handleSubmitLinkFile = async (e) => {
    e.preventDefault();
    if (linkFile.title.length === 0) {
      toast.error("you have to add a title", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    } else {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/docs/upload-link`, {
          linkFile,
          entityId,
        })
        .then((res) => {
          console.log("res.data :>> ", res.data);
          toast.success("add link success", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
          closeModal();
        })
        .catch((err) => {
          console.log("err :>> ", err);
          toast.error("an error was occured", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
        });
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setLinkFile((prevLinkFile) => {
      return {
        ...prevLinkFile,
        [name]: value,
      };
    });
  };

  return (
    <section className="client-file-section">
      <div className="container-file-page">
        <FilesFolder category={allCategories} filterItem={filterFile} />

        {authState.role === "admin" || authState.role === "consultant" ? (
          <div className="div-add-files">
            <button className="modal-button-addfiles" onClick={openModal}>
              <VscFiles size={20} style={{ marginRight: "4px" }} />
              Add Files
            </button>
          </div>
        ) : (
          ""
        )}

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Modal"
        >
          <section className="section-modal-addfiles">
            <div
              className="close-modal-stp"
              style={{ cursor: "pointer" }}
              onClick={closeModal}
            >
              <div className="close-modal-button-stp">
                <GrFormClose size={15} className="cross-modal-stp" />
              </div>
            </div>
            <div className="bloc-tabs">
              <button
                className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                onClick={() => toggleTab(1)}
              >
                Upload files
              </button>
              <button
                className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                onClick={() => toggleTab(2)}
              >
                Upload links
              </button>
            </div>
            <div className="content-tabs">
              <div
                className={
                  toggleState === 1 ? "content  active-content" : "content"
                }
              >
                <DragAndDrop
                  entityId={entityId && entityId}
                  modalIsOpen={modalIsOpen}
                  setIsOpen={setIsOpen}
                  setFiles={setFiles}
                  setCanICall={setCanICall}
                />
              </div>
              <div
                className={
                  toggleState === 2 ? "content  active-content" : "content"
                }
              >
                {/* <h2 className="h2-title-upload-links">Upload Links</h2> */}
                <div className="upload-links-modal">
                  <form onSubmit={handleSubmitLinkFile}>
                    <label className="label-title">Title</label>
                    <input
                      className="input-upload-links-title"
                      name="title"
                      // value={title}
                      type="text"
                      placeholder="Link title"
                      onChange={(e) => handleChange(e)}
                    />
                    <label className="label-link">Link</label>
                    <input
                      className="input-upload-links-link"
                      name="link"
                      // value={lien}
                      type="text"
                      placeholder="Paste your link"
                      onChange={(e) => handleChange(e)}
                    />
                    <div className="buttons-add-links-modal">
                      <button
                        className="btn-add-links-modal"
                        onClicktype="submit"
                      >
                        Upload
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </Modal>
        <FilesList
          downLoadFileGcp={downLoadFileGcp}
          removeFile={removeFile}
          files={items}
          handle={handle}
          setFiles={setFiles}
          entityId={entityId}
        />
      </div>
    </section>
  );
};

export default ClientFile;
