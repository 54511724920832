import { React, createContext, useState } from "react";
import DashBoard from "../components/Dashboard";
export const multiStepContext = createContext();
const StepContext = () => {
  const [currentStep, setStep] = useState(1);
  const [userData, setUserData] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [finalData, setFinalData] = useState([]);
  const [contactData, setContactData] = useState([
    {
      email: "premier@gmail.com",
    },
    {
      email: "deuxieme@gmail.com",
    },
    {
      email: "trois@gmail.com",
    },
    {
      email: "quatres@gmail.com",
    },
  ]);

  const submitData = () => {
    setFinalData((finalData) => [...finalData, userData]);
    setUserData("");
    setFileData(null);
    setContactData();
    /* setStep(1); */
  };
  return (
    <multiStepContext.Provider
      value={{
        currentStep,
        setStep,
        userData,
        setUserData,
        finalData,
        setFinalData,
        submitData,
        fileData,
        setFileData,
        contactData,
        setContactData,
      }}
    >
      <DashBoard />
    </multiStepContext.Provider>
  );
};

export default StepContext;
