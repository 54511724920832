import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { RiDragDropLine } from "react-icons/ri";
import { AiOutlineWarning } from "react-icons/ai";
import pdf from "../assets/Icones/pdf.svg";
import png from "../assets/Icones/png.svg";
import doc from "../assets/Icones/doc.svg";
import ppt from "../assets/Icones/ppt.svg";
import txt from "../assets/Icones/txt.svg";
import xls from "../assets/Icones/xls.svg";
import zip from "../assets/Icones/zip.svg";
import jpg from "../assets/Icones/jpg.svg";
import { formatBytes } from "../utils/utils";
import axios from "axios";
import { toast } from "react-toastify";
/* import { AiOutlineTags } from "react-icons/ai"; */
import { Loader } from "@mantine/core";

toast.configure();

const DragAndDrop = ({
  entityId,
  modalIsOpen,
  setIsOpen,
  setFiles,
  setCanICall,
}) => {
  const [fileData, setFileData] = useState(null);

  /*   const maxSize = 1048576; */
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFileData(acceptedFiles);
      /* setFiles(acceptedFiles); */
      // /* setUserData({ ...userData, files: acceptedFiles }) */ console.log(
      //   acceptedFiles,
      //   "acceptedFiles"
      // );
    },
    [/* userData, setUserData, */ setFileData]
  );
  const remove = (file) => {
    console.log("file :>> ", file);
    const newFiles = [...fileData]; // make a var for the new array
    console.log("newFiles :>> ", newFiles);
    newFiles.splice(file, 1); // remove the file from the array
    setFileData(newFiles); // update the state
  };
  console.log("good file");
  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    /*   acceptedFiles, */
    fileRejections,
    isFileTooLarge,
  } = useDropzone({
    onDrop,
    //remove to accep onl file specified
    accept:
      "image/png, text/plain, application/msword, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/jpeg, image/jpg,  application/vnd.ms-powerpoint, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,  application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/zip",
    minSize: 0,
    /*  maxSize: 20971520, */
    multiple: true,
  });
  const [loading, setLoading] = useState(false);
  console.log("loading :>> ", loading);
  const fileRejectionItems = fileRejections.map(({ file, index }) => (
    <div key={index} className="rejected-files-draganddrop">
      <div className="rejected-files-description">
        <span>
          <AiOutlineWarning size={16} />
          {file.path} - {formatBytes(file.size)} - File is larger than 20MB
        </span>
        {/* <span>
          {errors.map((e) => {
            console.log(e);
            return <p key={e.code}>{e.message}</p>;
          })}
        </span> */}
      </div>
    </div>
  ));

  // const files = acceptedFiles.map((file) => (
  //   <li className="files-card" key={file.path}>
  //     {file.path} - {file.size} bytes
  //   </li>
  // ));

  const displayFileExtensionImage = (file) => {
    const getFileExtension = file.name.slice(-3);
    switch (getFileExtension) {
      case "pdf":
        return <img src={pdf} alt={file.name} />;
      case "png":
        return <img src={png} alt={file.name} />;
      case "doc":
        return <img src={doc} alt={file.name} />;
      case "ppt":
        return <img src={ppt} alt={file.name} />;
      case "txt":
        return <img src={txt} alt={file.name} />;
      case "xls":
        return <img src={xls} alt={file.name} />;
      case "zip":
        return <img src={zip} alt={file.name} />;
      case "jpg":
        return <img src={jpg} alt={file.name} />;
      case "peg":
        return <img src={jpg} alt={file.name} />;
      case "ocx":
        return <img src={doc} alt={file.name} />;
      case "ent":
        return <img src={doc} alt={file.name} />;
      case "lsx":
        return <img src={xls} alt={file.name} />;
      case "eet":
        return <img src={xls} alt={file.name} />;
      case "ptx":
        return <img src={ppt} alt={file.name} />;
      case "ion":
        return <img src={ppt} alt={file.name} />;
      default:
        break;
    }
  };

  const handleSubmitFiles = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("uploading files to bucket...");

    let filesToUpload = fileData.length;
    let currentUpload = 0;
    console.log(filesToUpload);

    fileData.forEach(async (file) => {
      console.log(`getting signed url for ${file.name}`);
      const { signedURL, fileName } = await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/docs/upload-signed?file=${file.name}`
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log("err :>> ", err);
          toast.error("an error was occured while getting the signed URL", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
        });
      console.log(signedURL);

      console.log(file);
      const formData = new FormData();
      formData.append("originalName", file.name);
      formData.append("fileType", file.type);
      formData.append("fileSize", file.size);
      formData.append("filename", fileName);
      formData.append("entityId", entityId);
      console.log(`uploading ${file.name} with signed URL`);
      await axios
        .put(signedURL, file, {
          headers: {
            "Content-type": "application/octet-stream",
          },
        })
        .then(async (res) => {
          console.log(res);
          console.log(`sending file info to API for ${file.name}`);
          await axios
            .post(`${process.env.REACT_APP_API_URL}/api/docs/upload`, formData)
            .then((res) => {
              console.log("res.data :>> ", res.data);
              toast.success(`${file.name} upload successfuly`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
              });
              currentUpload++;
              console.log(`${currentUpload}/${filesToUpload}`);
              if (currentUpload === filesToUpload) {
                setLoading(false);
                setIsOpen(false);
                setCanICall(true);
              }
            })
            .catch((err) => {
              console.log("err :>> ", err);
              toast.error("an error was occured", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
              });
            });
        })
        .catch((err) => {
          console.log("err :>> ", err);
          toast.error("an error was occured while uploading the files", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
        });
    });
  };

  /*   const customSingleValue = ({ data }) => (
    <div className="input-select">
      <div className="input-select__single-value">
        {data.icon && <span className="input-select__icon">{data.icon}</span>}
        <span>{data.label}</span>
      </div>
    </div>
  );

  const customStyles = {
    option: (provided, state) => ({
      ...provided,

      borderBottom: "1px dotted #F6F6F6",
      color: state.isSelected ? "#6935d7" : "black",
      padding: 20,
      fontSize: 10,
      background: "white",
      display: "flex",
      borderRadius: "0.625rem",
      "&:hover": {
        background: "#F6F6F6",
      },
    }),

    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),

    indicatorSeparator: () => ({
      border: "none",
    }),

    indicatorsContainer: () => ({
      display: "none",
    }),

    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: 76,
      color: "black",
      display: "flex",
      fontSize: 10,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "0.625rem",
      backgroundColor: "#F4F4F4",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };
 */
  return (
    <div className="third-step">
      <section className="drag-and-drop-section">
        {/* <h2 className="upload-files">Upload Files</h2> */}
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="drag-and-drop">
            {!isDragActive && (
              <div className="drag-msg">
                <RiDragDropLine
                  className="drag-icon"
                  style={{ marginRight: "20px" }}
                  size={24}
                />
                Drag and drop files here or
                <span className="choose-file">choose file</span>
              </div>
            )}
            {isDragActive &&
              !isDragReject &&
              "Succes ! Drag an other file or submit if you are ready !"}
            {isDragReject && "File type not accepted, sorry!"}
            {isFileTooLarge && (
              <div className="text-danger mt-2">File is too large.</div>
            )}
          </div>
        </div>
        <div className="infos-files">
          <p className="files-supported">
            Files supported: PDF, XSLS, DOC, Image ...
          </p>
          <p className="files-size">Maximum size: 1GB</p>
        </div>

        <h4 className="title-uploaded">Uploaded Files</h4>
        <aside className="aside-draganddrop">
          <div className="card-files-modal">
            {fileData &&
              fileData.map((acceptedFile, index) => {
                return (
                  <div key={index} className="list-files-modal">
                    <div className="close-drag">
                      <GrFormClose
                        style={{ cursor: "pointer" }}
                        size={15}
                        className="cross"
                        onClick={() => remove(index)}
                      />
                    </div>
                    <div key={index} className="file-card">
                      {/* <div className="select-container-tags">
                        <Select
                          className="select-tags"
                          styles={customStyles}
                          options={tagsName}
                          placeholder="add tags"
                          components={{ SingleValue: customSingleValue }}
                          // onChange={(obj) => handle(obj)}
                        />
                      </div> */}
                      <div className="file-display">
                        <div className="file-icon">
                          {displayFileExtensionImage(acceptedFile)}
                        </div>
                        <span className="file-name">{acceptedFile.name}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {fileRejectionItems.length !== 0 ? (
            <h4 className="rejected-files-title">Rejected Files</h4>
          ) : (
            ""
          )}
          <div>{fileRejectionItems}</div>
        </aside>

        <div className="div-button-modal">
          {loading ? (
            <Loader color="violet" size="lg" variant="bars" />
          ) : (
            <button
              className="btn-upload-files"
              style={{ cursor: "pointer" }}
              onClick={handleSubmitFiles}
            >
              Upload
            </button>
          )}
        </div>
      </section>
    </div>
  );
};

export default DragAndDrop;
