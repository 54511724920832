import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Switch, Route, NavLink, useRouteMatch } from "react-router-dom";
import ClientFile from "./clientPage/ClientFile";
import ClientSetting from "./clientPage/ClientSetting";
import { AuthContext } from "../auth/auth";
import { Redirect } from "react-router";
import { ClientInfosContext } from "../context/ClientInfosContext";
import BasicInformation from "./manage-client-composant/BasicInformation";
import TeamMembers from "./manage-client-composant/TeamMembers";
import Dashboards from "./manage-client-composant/Dashboards";
import Contact from "./manage-client-composant/Contact";

const ClientComponent = () => {
  const { id } = useParams();
  const [name, setName] = useState(null);
  const [files, setFiles] = useState([]);
  const [links, setLinks] = useState();
  const [image, setImage] = useState(null);
  const [roadmapLink, setRoadmapLink] = useState([]);
  const [consultants, setConsultant] = useState([]);
  const [email, setEmail] = useState(null);

  const { authState } = useContext(AuthContext);
  const { setClientName, setClientImage, setClientId } =
    useContext(ClientInfosContext);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/entity/${id}`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        setName(res.data.entity.name);
        setFiles(res.data.entity.docs);
        setLinks(res.data.entity.datastudio);
        setImage(res.data.entity.image);
        setRoadmapLink(res.data.entity.roadmap);
        setConsultant(res.data.entity.user);
        setEmail(res.data.entity.contact);
        setClientImage(res.data.entity.image);
        setClientName(res.data.entity.name);
        setClientId(id);
      })
      .catch((error) => {
        console.log("error :>> ", error);
      });

    return () => {
      setName();
      setFiles();
      setLinks();
      setRoadmapLink();
      setEmail();
    };
  }, [id]);
  let { path, url } = useRouteMatch();

  if (!authState.status) {
    return <Redirect to="/" />;
  }

  return (
    <section className="client-page-section">
      <div className="manage-client-div">
        <h1 className="title-manage-client">Manage client</h1>
        <nav className="client-page-navbar">
          <div className="link-div">
            <NavLink to={`${url}/basic-information`}>Basic information</NavLink>
            <NavLink to={`${url}/client-team-member`}>Teams members</NavLink>
            <NavLink to={`${url}/client-page-dashboard`}>Dashboards</NavLink>
            <NavLink to={`${url}/client-page-contacts`}>Contacts</NavLink>
            {/* <Link to={`${url}/client-setting`}>Settings</Link> */}
          </div>
        </nav>
        <Switch>
          <Route path={`${path}/client-file`}>
            <ClientFile files={files} setFiles={setFiles} entityId={id} />
          </Route>
          <Route path={`${path}/client-team-member`}>
            {/* <ClientDasboard links={links} /> */}
            <TeamMembers
              consultants={consultants}
              setConsultant={setConsultant}
              id={id}
            />
          </Route>
          <Route path={`${path}/client-page-dashboard`}>
            <Dashboards
              links={links}
              id={id}
              setLinks={setLinks}
              roadmapLink={roadmapLink}
              setRoadmapLink={setRoadmapLink}
            />
          </Route>
          <Route path={`${path}/basic-information`}>
            {/*         <ClientSetting
            id={id}
            image={image}
            setImage={setImage}
            links={links}
            roadmapLink={roadmapLink && roadmapLink}
            consultants={consultants}
            setConsultant={setConsultant}
            setLinks={setLinks}
            name={name}
            email={email}
            setEmail={setEmail}
            setName={setName}
            setRoadmapLink={setRoadmapLink}
          /> */}
            <BasicInformation
              id={id}
              image={image}
              name={name}
              setImage={setImage}
              email={email}
              setName={setName}
              consultants={consultants}
              files={files}
            />
          </Route>
          <Route path={`${path}/client-page-contacts`}>
            <Contact id={id} />
          </Route>
        </Switch>
      </div>
    </section>
  );
};

export default ClientComponent;
