import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { BsArrowUpLeft } from "react-icons/bs";
import { ClientInfosContext } from "../../context/ClientInfosContext";
import Select from "react-select";
import { Redirect } from "react-router";
import { AuthContext } from "../../auth/auth";

const ClientRoadmap = () => {
  const { clientId } = useContext(ClientInfosContext);
  const { authState } = useContext(AuthContext);

  const [roadmapLinks, setRoadmapLinks] = useState("");
  const [liens, setLink] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/entity/${clientId}`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        console.log("res :>> ", res);

        if (res.data.entity.roadmap.length !== 0) {
          setRoadmapLinks(res.data.entity.roadmap);
        }

        if (res.data.entity.roadmap.length !== 0) {
          setTitle(res.data.entity.roadmap[0].title);
        }

        /* setShowClientNav(true); */
        console.log("res.data.entity.roadmap :>> ", res.data.entity.roadmap);
      })
      .catch((error) => {
        console.log("error :>> ", error);
      });

    return () => {
      setRoadmapLinks();
      setTitle();
      /* setShowClientNav(false); */
    };
  }, [clientId]);

  console.log("title :>> ", title);
  console.log("links :>> ", roadmapLinks);
  console.log("liens:>> ", liens);

  let options =
    roadmapLinks &&
    roadmapLinks.length !== 0 &&
    roadmapLinks.map((road, index) => {
      const { roadmapLink, title } = road;
      return { value: roadmapLink, label: title };
    });

  const handleChange = (obj) => {
    console.log("obj :>> ", obj);
    setLink(obj.value);
  };

  useEffect(() => {
    if (roadmapLinks && roadmapLinks.length !== 0) {
      console.log("roadmapLinks :>> ", roadmapLinks);
      setLink(roadmapLinks[0].roadmapLink);
    }
  }, [roadmapLinks]);

  const customStyles3 = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted #F6F6F6",
      color: state.isSelected ? "#6935d7" : "black",

      padding: 20,
      borderRadius: "0.625rem",
      background: "white",
      "&:hover": {
        background: "#F6F6F6",
      },
    }),
    indicatorSeparator: () => ({}),
    indicatorsContainer: () => ({
      display: "flex",
      alignItems: "center",
      marginRight: "15px",
      cursor: "pointer",
    }),

    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      width: 312,
      height: 57,
      backgroundColor: "white",
      border: "1px solid #ececee",
      borderRadius: "0.225rem",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  console.log("options :>> ", options);
  console.log("options[0] :>> ", options && options[0]);
  if (!authState.status) {
    return <Redirect to="/" />;
  }

  console.log("roadmapLinks :>> ", roadmapLinks);

  return (
    <section className="client-section-dashboard">
      {(roadmapLinks && roadmapLinks.length === 0) ||
      roadmapLinks === undefined ? (
        <div className="no-datastudio">No roadmap available for now</div>
      ) : (
        <>
          <div className="description-dashboards-top">
            <div className="title-description-dashboards">
              <span>DEVELOPPED BY YURI AND NEIL</span>
              <h2>Roadmap</h2>
            </div>
            <div className="select-container">
              <Select
                className="select-dashboards"
                styles={customStyles3}
                options={options}
                placeholder="Select Datastudio"
                onChange={(obj) => handleChange(obj)}
                defaultValue={options && options[0]}
              />
            </div>
          </div>
          <iframe
            title="monifram"
            className="iframe"
            src={liens && liens}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </>
      )}
    </section>
  );
};

export default ClientRoadmap;
