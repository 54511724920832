import axios from "axios";
import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
toast.configure();
const ClientSetting = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const handleSubmitPassword = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      console.log("ne correspondent pas");
      toast.error("password are not the same", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
    axios
      .put("")
      .then((res) => {
        console.log("res.date :>> ", res.data);
        toast.success("change password success", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.log("err :>> ", err);
        toast.error("An error was occured", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      });
  };

  return (
    <section className="section-mysetting-client">
      <div className="content-mysetting-client">
        <h1 className="title-mysetting-client">My Settings</h1>
        <span className="reset-password-client">Reset password</span>
        <form onSubmit={handleSubmitPassword}>
          <div className="container-password">
            <div className="input-label-password">
              <label>Password</label>
              <input
                type="password"
                placeholder=" New password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="input-label-password">
              <label>Confirm Password</label>
              <input
                type="password"
                placeholder="Confirm new password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>

            <button className="confirm-btn" type="submit">
              Confirm
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ClientSetting;
