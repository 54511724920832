import React, { useContext } from "react";
import FirstStep from "./stepForm/firstStep";
import SecondStep from "./stepForm/secondStep";
import ThirdStep from "./stepForm/thirdStep";
import FourthStep from "./stepForm/fourthStep";
import { multiStepContext } from "../context/stepContext";
import NavStepper from "./stepForm/NavStepper";
import LastStep from "./stepForm/LastStep";
import { Redirect } from "react-router";
import { AuthContext } from "../auth/auth";

const DashBoard = () => {
  //remove third step to replace it by another step
  /*      case 3:
        return <ThirdStep />; */
  const { currentStep } = useContext(multiStepContext);
  const { authState } = useContext(AuthContext);
  function showStep(step) {
    // eslint-disable-next-line default-case
    switch (step) {
      case 1:
        return <FirstStep />;
      case 2:
        return <SecondStep />;
      case 3:
        return <FourthStep />;
      case 4:
        return <LastStep />;
    }
  }
  if (!authState.status) {
    return <Redirect to="/" />;
  }

  return (
    <section className="dashboard-section">
      <NavStepper />
      {/*         <Stepper
          style={{
            height: "30rem",
          }}
          activeStep={currentStep - 1}
          orientation="vertical"
        >
          <Step>
            <StepLabel>Basic informations</StepLabel>
          </Step>
          <Step>
            <StepLabel>Import Files</StepLabel>
          </Step>
          <Step>
            <StepLabel>Connect your tools</StepLabel>
          </Step>
        </Stepper> */}
      {showStep(currentStep)}
    </section>
  );
};

export default DashBoard;
