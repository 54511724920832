import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../auth/auth";
import { useHistory } from "react-router-dom";
import rockethouston from "../assets/illu-houston.png";
import filesmenu from "../assets/Files-menu.svg";
import roadmapmenu from "../assets/Roadmap-menu.svg";
import dashboardmenu from "../assets/Dashboard-menu.svg";
import { ClientInfosContext } from "../context/ClientInfosContext";
import { RiTeamFill } from "react-icons/ri";
import { IoSettingsOutline } from "react-icons/io5";
import { BeamerHelmet } from "react-beamer";
import { FaGraduationCap } from "react-icons/fa";
import { BiBookAlt } from "react-icons/bi";

const ClientLoginAccueil = () => {
  const { authState } = useContext(AuthContext);
  const { setClientId, setShowClientNav, setName, setImage, setEmail } =
    useContext(ClientInfosContext);

  console.log("authState.entityId :>> ", authState.entityId);
  const productId = "fZsZYiNU42257";

  const [entity, setEntity] = useState([]);
  const history = useHistory();
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/entity/${authState.entityId}`,
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      )
      .then((res) => {
        console.log("res.data :>> ", res.data);
        setEntity(res.data.entity);
        setClientId(res.data.entity.id);
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
  }, [authState.entityId]);
  const user = localStorage.getItem("user");
  useEffect(() => {
    const userParsed = JSON.parse(user);
    if (user) {
      setName(userParsed.name);
      setImage(userParsed.image);
    }
  }, [user, setImage, setName]);

  console.log("entity :>> ", entity);

  return (
    <section className="client-accueil">
      <div className="container-description-img">
        <BeamerHelmet className="display-page" id={productId} />
        <div className="img-accueil">
          <img
            className="rocket-houston"
            src={rockethouston}
            alt="rckthouston"
          />
        </div>
        <div className="description-accueil">
          <div className="title-houston">
            <h1>Houston Platform</h1>
            <p>Beta version 1.0</p>
          </div>

          <h2 className="heading02--Semi-Bold">
            All your necessary tools in one place
          </h2>
        </div>
      </div>

      <section className="homepage-menu-grid">
        <div
          className="block-homepage-client"
          onClick={() => {
            setShowClientNav(true);
            history.push(`/accueil/client-file/${authState.entityId}`);
          }}
        >
          <img className="files-icon" src={filesmenu} alt="files-icon" />
          <h4 className="title-block">Files</h4>
          <p>Find all your space travel documents</p>
        </div>

        <div
          className="block-homepage-client"
          onClick={() => {
            setShowClientNav(true);
            history.push(`/accueil/client-page-roadmap/${authState.entityId}`);
          }}
        >
          <img className="roadmap-icon" src={roadmapmenu} alt="roadmap-icon" />
          <h4 className="title-block">Roadmap</h4>
          <p>Follow the progress of your spaceship in real time</p>
        </div>

        <div
          className="block-homepage-client"
          onClick={() => {
            setShowClientNav(true);
            history.push(
              `/accueil/client-page-dashboard/${authState.entityId}`
            );
          }}
        >
          <img
            className="dashboard-icon"
            src={dashboardmenu}
            alt="dashboard-icon"
          />
          <h4 className="title-block">Dashboard</h4>
          <p>Track performances in real time </p>
        </div>

        <div
          className="block-homepage-client"
          onClick={() => {
            setShowClientNav(true);
            history.push(`/accueil/client-page-myTeam/${authState.entityId}`);
          }}
        >
          <RiTeamFill className="myteam-icon" size={65} alt="setting-icon" />
          <h4 className="title-block">My team</h4>
          <p>Get to know all your space crew here</p>
        </div>
        <div
          className="block-homepage-client"
          onClick={() => {
            setShowClientNav(true);
            history.push(`/accueil/formation/${authState.entityId}`);
          }}
        >
          <FaGraduationCap
            className="setting-icon"
            size={65}
            alt="setting-icon"
          />
          <h4 className="title-block">Courses</h4>
          <p>
            Discover all our trainings available to become a rockstar on any
            digital topic
          </p>
        </div>
        <div
          className="block-homepage-client"
          onClick={() => {
            setShowClientNav(true);
            history.push(`/accueil/knowledgebase/${authState.entityId}`);
          }}
        >
          <BiBookAlt className="setting-icon" size={65} alt="setting-icon" />
          <h4 className="knowledge-title">Knowledge base</h4>
          <p>Tips, tools, templates: enjoy your free galactic toolbox</p>
        </div>

        <div
          className="block-homepage-client"
          onClick={() => {
            setShowClientNav(true);
            history.push(`/accueil/client-setting/${authState.entityId}`);
          }}
        >
          <IoSettingsOutline
            className="setting-icon"
            size={65}
            alt="setting-icon"
          />
          <h4 className="title-block">Settings</h4>
          <p>Manage your account</p>
        </div>
      </section>

      {/* <button
            className="getstarted-button"
            onClick={() => {
              history.push(`/client-page/${authState.entityId}/client-file`);
            }}
          >
            Get started
          </button> */}
    </section>
  );
};

export default ClientLoginAccueil;
