import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { NavLink, Link, useRouteMatch, useHistory } from "react-router-dom";
import astro from "../../assets/astro.jpg";
import { ClientInfosContext } from "../../context/ClientInfosContext";
import { ReactComponent as Knowledge } from "../../assets/knowledge-side.svg";
import { AuthContext } from "../../auth/auth";
import { ReactComponent as Filessvg } from "../../assets/files-side.svg";
import { ReactComponent as Formationsvg } from "../../assets/formation-side.svg";
import { ReactComponent as Dashboardsvg } from "../../assets/dashboard-side.svg";
import sidebarclose from "../../assets/sidebar-close.svg";
import sidebaropen from "../../assets/sidebar-open.svg";
import { BsInfoSquare } from "react-icons/bs";
import AdminLink from "./adminLink/AdminLink";
import ConsulantLink from "./consultantLink/ConsulantLink";
import ClientLink from "./clientLink/ClientLink";
import hand from "../../assets/pouce.svg";
import { FiLogOut } from "react-icons/fi";

const NavSidebar = () => {
  const { authState, setAuthState } = useContext(AuthContext);
  const [arrow, setArrow] = useState(true);
  const date = new Date();
  const [sayHi, setSayHi] = useState("");
  const history = useHistory();

  const hour = date.getHours();
  useEffect(() => {
    const greeting = () => {
      if (hour > 4 && hour < 12) {
        setSayHi("Good morning");
      } else if (hour > 12 && hour < 17) {
        setSayHi("Good afternoon");
      } else {
        setSayHi("Good evening");
      }
    };
    greeting();
  });
  let { url } = useRouteMatch();

  const {
    name,
    image,
    clientName,
    clientImage,
    clientId,
    showClientNav,
    setClientName,
    setImage,
    setName,
    setShowClientNav,
  } = useContext(ClientInfosContext);

  const logOut = () => {
    setAuthState({ ...authState, status: false });
    setClientName();
    setImage();
    setName();
    setShowClientNav();
    localStorage.clear();

    const reload = () =>
      authState.role === "client" ? window.location.reload() : "";

    reload();
  };
  console.log(process.env.NODE_ENV);
  return (
    <aside className={arrow ? "aside-navsidebar" : "aside-close"}>
      <div className="arrow">
        {arrow ? (
          <img
            src={sidebaropen}
            alt={sidebaropen}
            onClick={() => setArrow(!arrow)}
            className="arrow-sidebar"
          />
        ) : (
          <img
            src={sidebarclose}
            alt={sidebarclose}
            onClick={() => setArrow(!arrow)}
            className="arrow-sidebar"
          />
        )}
      </div>

      <div className="sidebar-display-user">
        <figure className="figure-img-admin">
          <img
            onClick={() => history.push(`${url}/home`)}
            src={image ? image : astro}
            alt={name && name}
          />

          <div className={arrow ? "welcome-msg-sidebar" : "display-none"}>
            <span>
              {sayHi} <img className="hand-svg" src={hand} alt={hand} />
            </span>
            <figcaption>{name ? name : ""}</figcaption>
          </div>
        </figure>
      </div>
      <div className="sidebar-display-cockpit-client">
        {clientName && (
          <figure className="figure-img-client">
            <img src={clientImage ? clientImage : astro} alt={clientName} />
          </figure>
        )}
      </div>
      <nav className="nav-sidebar">
        {clientName && (
          <div className="div-content-client">
            <h1>{clientName}</h1>
            <div className="div-link-client">
              <div className="links-img-sidebar">
                <NavLink
                  className="link-menu-sidebar"
                  to={`${url}/client-file/${clientId}`}
                >
                  <Filessvg />
                  <span
                    style={{ marginLeft: "0.7rem" }}
                    className={arrow ? "" : "display-none"}
                  >
                    Files
                  </span>
                </NavLink>
              </div>

              <NavLink
                to={
                  authState.role === "admin" || "consultant"
                    ? `${url}/client-page/${clientId}/basic-information`
                    : `${url}/client-page/${clientId}/client-team-member`
                }
              >
                <Dashboardsvg />
                <span
                  style={{ marginLeft: "0.7rem" }}
                  className={arrow ? "" : "display-none"}
                >
                  Manage Client
                </span>
              </NavLink>
            </div>
          </div>
        )}

        {showClientNav && authState.role === "client" ? (
          <ClientLink arrow={arrow} clientId={clientId} />
        ) : (
          ""
        )}
        {authState.role === "client" ? (
          <div className="div-link-consultant">
            {showClientNav ? (
              <div className="client-menu">
                <h1>BONUS</h1>
                <NavLink
                  className="link-menu-sidebar"
                  to={`${url}/knowledgebase`}
                >
                  <Knowledge style={{ marginLeft: "2px" }} />
                  <span
                    className={arrow ? "" : "display-none"}
                    style={{ marginLeft: "0.7rem" }}
                  >
                    Knowledge base
                  </span>
                </NavLink>
                <NavLink className="link-menu-sidebar" to={`${url}/formation`}>
                  <Formationsvg />
                  <span
                    className={arrow ? "" : "display-none"}
                    style={{ marginLeft: "0.6rem" }}
                  >
                    Courses
                  </span>
                </NavLink>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        <div className="div-content-consultant">
          <div className="div-link-consultant">
            {authState.role === "admin" ? <AdminLink arrow={arrow} /> : ""}
            {authState.role === "consultant" ? (
              <ConsulantLink arrow={arrow} />
            ) : (
              ""
            )}
          </div>
        </div>
      </nav>

      <footer>
        <div className={arrow ? "logout-div" : "client-here"} onClick={logOut}>
          <FiLogOut className="log-out-btn" size={20} />
          <span style={{ marginLeft: "0.7rem" }}>Logout</span>
        </div>

        <a
          className="link-to-ynsite"
          target="_blank"
          href="https://yuriandneil.com/"
          rel="noreferrer"
        >
          <h1>Houston Plateform v1</h1>
          <span className="dev-by-yn-msg">DEVELOPPED BY YURI AND NEIL</span>
        </a>
      </footer>
    </aside>
  );
};

export default NavSidebar;
