import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { Redirect } from "react-router";
import { AuthContext } from "../../auth/auth";
import { BsArrowUpLeft } from "react-icons/bs";
import axios from "axios";
import { ClientInfosContext } from "../../context/ClientInfosContext";

const ClientDasboard = () => {
  const { authState } = useContext(AuthContext);
  const { clientId /* setShowClientNav */ } = useContext(ClientInfosContext);

  const [links, setLinks] = useState("");
  const [liens, setLink] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/entity/${clientId}`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        console.log("res :>> ", res);
        /* setShowClientNav(true); */
        console.log("res.data.entity :>> ", res.data.entity);
        if (res.data.entity.datastudio.length !== 0) {
          setLinks(res.data.entity.datastudio);
        }

        if (res.data.entity.datastudio.length !== 0) {
          setTitle(res.data.entity.datastudio[0].title);
        }

        console.log(
          "res.data.entity.datastudio :>> ",
          res.data.entity.datastudio
        );
      })
      .catch((error) => {
        console.log("error :>> ", error);
      });

    return () => {
      setLinks();
      setTitle();
      /* setShowClientNav(false); */
    };
  }, [clientId]);

  // console.log("links.length :>> ", links.length);
  console.log("title :>> ", title);
  console.log("links :>> ", links);
  console.log("liens :>> ", liens);

  let options =
    links &&
    links.length !== 0 &&
    links.map((dash, index) => {
      console.log("dash :>> ", dash);
      const { link, title } = dash;
      return { value: link, label: title };
    });

  const handleChange = (obj) => {
    console.log("obj :>> ", obj);
    setLink(obj.value);
  };

  useEffect(() => {
    if (links && links.length !== 0) {
      setLink(links[0].link);
    }
  }, [links]);

  const customStyles3 = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted #F6F6F6",
      color: state.isSelected ? "#6935d7" : "black",

      padding: 20,
      borderRadius: "0.625rem",
      background: "white",
      "&:hover": {
        background: "#F6F6F6",
      },
    }),
    indicatorSeparator: () => ({}),
    indicatorsContainer: () => ({
      display: "flex",
      alignItems: "center",
      marginRight: "15px",
      cursor: "pointer",
    }),

    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      width: 312,
      height: 57,
      backgroundColor: "white",
      border: "1px solid #ececee",
      borderRadius: "0.225rem",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  console.log("options :>> ", options);
  console.log("options[0] :>> ", options && options[0]);

  if (!authState.status) {
    return <Redirect to="/" />;
  }

  // console.log("links.length :>> ", links.length);
  return (
    <section className="client-section-dashboard">
      {(links && links.length === 0) || links === undefined ? (
        <div className="no-datastudio">No datastudio available for now</div>
      ) : (
        <>
          <div className="description-dashboards-top">
            <div className="title-description-dashboards">
              <span>DEVELOPPED BY YURI AND NEIL</span>
              <h2>Dashboards</h2>
            </div>
            <div className="select-container">
              <Select
                className="select-dashboards"
                styles={customStyles3}
                options={options}
                placeholder="Select Datastudio"
                onChange={(obj) => handleChange(obj)}
                defaultValue={options && options[0]}
              />
            </div>
          </div>
          <iframe
            title="monifram"
            className="iframe"
            src={liens && liens}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </>
      )}
    </section>
  );
};

export default ClientDasboard;
