import React from "react";

const Formation = () => {
  return (
    <section className="section-formation">
      <iframe
        className="iframe-notion"
        id="notion"
        title="notion"
        frameBorder="0"
        src="https://www.hedy.co/formations-houston"
      ></iframe>
    </section>
  );
};

export default Formation;
