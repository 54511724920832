import React, { useContext, useEffect, useState } from "react";
import { multiStepContext } from "../../context/stepContext";
import Select from "react-select";
import { consultantName, consultantRole } from "../data";
import axios from "axios";

const SecondStep = () => {
  const { setStep, userData, setUserData } = useContext(multiStepContext);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted #F6F6F6",
      color: state.isSelected ? "#6935d7" : "black",
      padding: 20,
      background: "white",
      borderRadius: "0.625rem",
      "&:hover": {
        background: "#F6F6F6",
      },
    }),

    indicatorSeparator: () => ({
      border: "none",
    }),

    indicatorsContainer: () => ({
      borderLeft: "1px solid #f1f1f1",
      padding: "10px",
      marginRight: "2px",
      cursor: "pointer",
    }),

    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      width: 312,
      height: 57,
      backgroundColor: "white",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const customStyles2 = {
    option: (provided, state) => ({
      ...provided,

      borderBottom: "1px dotted #F6F6F6",
      color: state.isSelected ? "#6935d7" : "black",
      padding: 20,
      background: "white",
      borderRadius: "0.625rem",
      "&:hover": {
        background: "#F6F6F6",
      },
    }),

    indicatorSeparator: () => ({
      border: "none",
    }),
    indicatorsContainer: () => ({
      borderLeft: "1px solid #f1f1f1",
      padding: "10px",
      marginRight: "2px",
      cursor: "pointer",
    }),

    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      width: 312,
      height: 57,
      backgroundColor: "white",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const [consultantNameFromAPI, setConsultantNameFromApi] = useState();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/user/allConsultants`)
      .then((res) => {
        setConsultantNameFromApi(res.data);
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
  }, []);

  /*   console.log(
    "consultantNameFromAPI en haut :>> ",
    consultantNameFromAPI && consultantNameFromAPI
  );
  console.log("consultantName en dessous:>> ", consultantName); */
  //const [memberName] = useState(/* consultantName */ consultantNameFromAPI);
  const [memberRole] = useState(consultantRole);

  const [member, setMember] = useState([
    {
      name: "",
      role: "",
      id: "",
    },
  ]);

  const handleAddMembers = () => {
    setMember([...member, { name: "", role: "", id: "" }]);
  };

  const handleRemoveMembers = (index) => {
    const values = [...member];
    values.splice(index, 1);
    setMember(values);
  };
  /*   const handleChange = (e, index) => {
    console.log("index :>> ", index);
    const { name, value } = e.target;
    console.log("name,value :>> ", name, value);
    console.log("name :>> ", name);
    console.log("value :>> ", value);
    const list = [...member];
    console.log("list :>> ", list);
    list[index][name] = value;
    console.log("list[index][name] = value; :>> ", list[index][name]);
    setMember(list);
    setUserData({ ...userData, member: member });
  }; */

  const handle = (obj, index) => {
    const { name, value, id } = obj;
    console.log("obj :>> ", obj);
    const list = [...member];
    list[index][name] = value;
    console.log('list[index]["id"] = id; :>> ', (list[index]["id"] = id));
    setMember(list);
    // console.log("member :>> ", member);
    setUserData({ ...userData, member: member });
  };
  console.log("userData.member :>> ", userData.member);
  const handleRole = (obj, index) => {
    const { name, value } = obj;
    const list = [...member];
    list[index][name] = value;
    setMember(list);
    // console.log("member :>> ", member);
    setUserData({ ...userData, member: member });
  };
  return (
    <div className="secondstep-div">
      <p className="secondstep-para">2 -</p>
      <h1 className="h1-title">Who's working on the project?</h1>

      {member.map((el, index) => {
        const option =
          consultantNameFromAPI &&
          consultantNameFromAPI.map((el) => {
            return { value: el.name, label: el.name, name: "name", id: el.id };
          });
        return (
          <div className="container-select" key={index}>
            <div className="title-member-delete">
              <h5>Member</h5>
              {index === 0 ? (
                ""
              ) : (
                <span
                  style={{ cursor: "pointer" }}
                  className="span-delete"
                  onClick={handleRemoveMembers}
                >
                  Delete
                </span>
              )}
            </div>
            <div className="select-div">
              <Select
                className="select-name"
                options={option}
                styles={customStyles}
                placeholder="Select Member"
                onChange={(obj) => handle(obj, index)}
                name="name"
              />

              <Select
                options={memberRole}
                styles={customStyles2}
                placeholder="Select Role"
                onChange={(obj) => handleRole(obj, index)}
                name="role"
              />
            </div>
          </div>
        );
      })}
      {/*  {member.map((el, index) => {
        return (
          <div className="container-select" key={index}>
            <div className="title-member-delete">
              <h5>Member</h5>
              <span className="span-delete" onClick={handleRemoveMembers}>
                Delete
              </span>
            </div>
            <div className="select-div">
              <select
                className="select-name"
                name="name"
                value={el.name}
                onChange={(e) => handleChange(e, index)}
              >
                {memberName.map((el, index) => {
                  return (
                    <option key={index} value={el.name}>
                      {el.name}
                    </option>
                  );
                })}
              </select>
              <select
                className="select-role"
                name="role"
                value={el.role}
                onChange={(e) => handleChange(e, index)}
              >
                {memberRole.map((el, index) => {
                  return (
                    <option key={index} value={el.role}>
                      {el.role}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        );
      })} */}
      <button
        className="btn-add-more"
        style={{ margin: "15px" }}
        onClick={handleAddMembers}
      >
        + Add new
      </button>

      {/* <button onClick={() => setStep(1)}>Back</button> */}
      <button className="btn-next-active" onClick={() => setStep(3)}>
        Next
      </button>
      {/* <pre>{JSON.stringify(member, null, 2)}</pre> */}
      {/* <span className="skip">Skip</span> */}
    </div>
  );
};

export default SecondStep;
